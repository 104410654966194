import { Button, Col, Row } from "react-bootstrap"
import ManualAccessibilityAuditBlock from "../ManualAccessibilityAuditBlock"
import { useTranslation } from "react-i18next";

import "../../Assets/scss/components/normal-dashboard.scss";
import { StoreProptypes } from "../../PropTypes/StoreProptypes";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import ViolationsTableData from "./ViolationsTable";
import { generatePDFService } from "../../Services/DashboardPDFReportService";
import InitMsgBlock from "../InitMsgBlock";


const NormalUserDashboardBlock = () => {

    const { t } = useTranslation();

    // Redux Data
    const { accessToken, activeLanguage, currentWebsite, currentWebsiteData, currentWebsitePlanStatus } = useSelector((store: StoreProptypes) => store);

    // state
    const [violationsType, setViolationsType] = useState<number>(1);
    const [isViolationsTypeButton, setIsViolationsTypeButton] = useState<boolean>(false);
    const [reportPDFLink, setReportPDFLink] = useState<string>('');
    const [loadingPDF, setLoadingPDF] = useState<boolean>(true);

    useEffect(() => {

        if ((currentWebsitePlanStatus.isProPlan || currentWebsitePlanStatus.isTrial)) {
            if (accessToken && currentWebsite && activeLanguage) {
                generatePDFService(accessToken, currentWebsite.id, activeLanguage.code, setLoadingPDF, setReportPDFLink);
            }
        }

        if (currentWebsitePlanStatus.isExpired || currentWebsitePlanStatus.isFreeWidget) {
            setViolationsType(0);
            setIsViolationsTypeButton(true);
        } else {
            setViolationsType(1);
            setIsViolationsTypeButton(false);
        }

    }, [currentWebsite])

    const handlePDFDownload = () => {
        if (reportPDFLink) {
            window.open(reportPDFLink, '_blank');
        }
    }

    return (
        <>
            <Row>
                <Col className="col-12 col-xxxl-12 col-xxxxl-9">
                    <div className={`aioa_dashboard-violations-info-wrapper ${currentWebsitePlanStatus.isInit ? `website-init-msg` : ``}`}>

                        {currentWebsitePlanStatus.isInit && (
                            <>
                                <InitMsgBlock />
                            </>
                        )}

                        <div className="aioa_dashboard-violations-graph">
                            <Row xs={1} md={3} className="gy-4 gy-md-0">
                                <Col>
                                    <div className="aioa_dashboard-violations-graph-box">
                                        <h2 className="aioa_dashboard-violations-graph-box-title h5">{t('accessibility score label')}</h2>
                                        <div className="aioa_dashboard-violations-semi-donut-graph" style={{ "--percentage": currentWebsiteData.accessibilityScore, "--fill": '#CADAEA' } as React.CSSProperties}>
                                            <div className="aioa_dashboard-violations-graph-box-per h2">{currentWebsiteData.accessibilityScore}%</div>
                                            <div className="aioa_dashboard-violations-graph-box-info h6 mb-0 text-success bold-text">Good</div>
                                        </div>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="aioa_dashboard-violations-graph-box">
                                        <h2 className="aioa_dashboard-violations-graph-box-title h5">{t('violations remediated label')}</h2>
                                        <div className="aioa_dashboard-violations-semi-donut-graph" style={{ "--percentage": currentWebsiteData.violationsRemediatedPercentage, "--fill": '#CADAEA' } as React.CSSProperties}>
                                            <div className="aioa_dashboard-violations-graph-box-per h2">{currentWebsiteData.violationsRemediatedPercentage}%</div>
                                            <div className="aioa_dashboard-violations-graph-box-info h6 mb-0">{t('out of out', { firstNumber: currentWebsiteData.successViolations, lastNumber: (currentWebsiteData.failedViolations + currentWebsiteData.successViolations) })}</div>
                                        </div>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="aioa_dashboard-violations-graph-box">
                                        <h2 className="aioa_dashboard-violations-graph-box-title h5">{t('alt text remediated label')}</h2>
                                        <div className="aioa_dashboard-violations-semi-donut-graph" style={{ "--percentage": currentWebsiteData.imageAltRemediatedPercentage, "--fill": '#CADAEA' } as React.CSSProperties}>
                                            <div className="aioa_dashboard-violations-graph-box-per h2">{currentWebsiteData.imageAltRemediatedPercentage}%</div>
                                            <div className="aioa_dashboard-violations-graph-box-info h6 mb-0">{t('out of out', { firstNumber: currentWebsiteData.successImageAlt, lastNumber: (currentWebsiteData.successImageAlt + currentWebsiteData.failedImageAlt) })}</div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>

                        <div className="aioa_dashboard-violations-list mt-4">

                            <div className="aioa_dashboard-violations-list-title-wrapper">
                                <div className="aioa_dashboard-violations-list-title h5 mb-0">{t('accessibility violations section title')}</div>
                                <div className="aioa_dashboard-violations-list-tab">
                                    <ul>
                                        <li><Button className={`${violationsType === 1 ? `active` : ``}`} size="sm" onClick={() => setViolationsType(1)} disabled={isViolationsTypeButton}>{t('violations remediated label')}</Button></li>
                                        <li><Button className={`${violationsType === 0 ? `active` : ``}`} size="sm" onClick={() => setViolationsType(0)}>{t('violations manual action tab label')}</Button></li>
                                    </ul>
                                </div>
                            </div>

                            {violationsType === 1 ? (
                                <>
                                    <ViolationsTableData violationsList={currentWebsiteData.successViolationsList} violationsType={1} />
                                </>
                            ) : (
                                <>
                                    <ViolationsTableData violationsList={currentWebsiteData.failedViolationsList} violationsType={0} />
                                </>
                            )}

                        </div>
                    </div>
                    {(currentWebsitePlanStatus.isProPlan || currentWebsitePlanStatus.isTrial) && (!currentWebsitePlanStatus.isInit) && (
                        <>
                            <div className="aioa_dashboard-violations-report-wrapper">
                                <Button size="lg" disabled={loadingPDF} onClick={() => handlePDFDownload()}>{t('get pdf report label')}</Button>
                            </div>
                        </>
                    )}
                </Col>
                <Col className="col-12 col-xxxl-12 col-xxxxl-3">
                    <ManualAccessibilityAuditBlock />
                </Col>
            </Row>
        </>
    )
}

export default NormalUserDashboardBlock;
