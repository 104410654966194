import { Button, Card, Form, Modal } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux";
import { StoreProptypes } from "../../PropTypes/StoreProptypes";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { SET_MESSAGE } from "../../Actions/Types";

import "../../Assets/scss/components/copy-widget-script-code.scss";

const ReactAppAPIKeyChanges = `${process.env.REACT_APP_API_KEY_CHANGES}`;

interface PropsTypes {
    widgetAPIKey: string,
    widgetPosition: string,
    widgetColor: string
}

const CopyWidgetScriptCode = (props: PropsTypes) => {
    // get Props
    const {widgetAPIKey, widgetPosition, widgetColor} = props;

    // Redux Data
    const { currentWebsite } = useSelector((store: StoreProptypes) => store);

    // Functions
    const { t } = useTranslation();
    const dispatch = useDispatch();

    // States
    const [advanceCodeInfoModel, setAdvanceCodeInfoModel] = useState<boolean>(false);
    const [codeToggle, setCodeToggle] = useState<boolean>(false);
    const [widgetCode, setWidgetCode] = useState('');
    const [copyCodeSuccess, setCopyCodeSuccess] = useState<boolean>(false);


    useEffect(() => {

        let widgetColorFinal: string;

        if(widgetColor.indexOf('#')) {
            widgetColorFinal = `#${widgetColor}`;
        } else {
            widgetColorFinal = widgetColor;
        }

        if (ReactAppAPIKeyChanges === 'true') {
            setWidgetCode(`<script>setTimeout(() => { let aioa_script_tag = document.createElement("script"); aioa_script_tag.src = "https://www.skynettechnologies.com/accessibility/js/all-in-one-accessibility-js-widget-minify.js?colorcode=${widgetColorFinal}&position=${widgetPosition}"; aioa_script_tag.defer="true"; aioa_script_tag.id = "aioa-adawidget"; document.getElementsByTagName("body")[0].appendChild(aioa_script_tag); }, 3000);</script>`);
        } else {
            if (codeToggle) {
                setWidgetCode(`<script>setTimeout(() => { let aioa_script_tag = document.createElement("script"); aioa_script_tag.src = "https://www.skynettechnologies.com/accessibility/js/all-in-one-accessibility-js-widget-minify.js?colorcode=${widgetColorFinal}&token=${widgetAPIKey}&position=${widgetPosition}";  aioa_script_tag.id = "aioa-adawidget";aioa_script_tag.defer="true"; document.getElementsByTagName("body")[0].appendChild(aioa_script_tag); }, 3000);</script>`);
            } else {
                setWidgetCode(`<script id="aioa-adawidget" src="https://www.skynettechnologies.com/accessibility/js/all-in-one-accessibility-js-widget-minify.js?colorcode=${widgetColorFinal}&token=${widgetAPIKey}&position=${widgetPosition}" defer></script>`);
            }
        }
    }, [codeToggle, widgetAPIKey, widgetPosition, widgetColor])

    // Model Function
    const handleShowWidgetAdvanceCodeInfoModel = () => {
        setAdvanceCodeInfoModel(true);
    }
    const handleHideWidgetAdvanceCodeInfoModel = () => {
        setAdvanceCodeInfoModel(false);
    }

    // Copy Code
    const handleWidgetCopyCode = () => {
        navigator.clipboard.writeText(widgetCode);
        setCopyCodeSuccess(true);
        dispatch({
            type: SET_MESSAGE,
            payload: {
                "toast_message": t('Copied'),
                "toast_type": "success"
            }
        })

        setTimeout(() => {
            setCopyCodeSuccess(false);
        }, 5000)
    }

    return (
        <>
            <Card className="aioa_dashboard-copy-widget-code">
                <Card.Body>

                    <div className='mb-2'><strong>Step 01</strong> : {t('copy code step 01')}</div>
                    <div className='mb-3'><strong>Step 02</strong> : {t('copy code step 02')}</div>

                    <div className='aioa_dashboard-widget-code'>
                        <div className='aioa_dashboard-widget-code-copy-btn'>
                            <Button aria-label={t("Copy widget embed code").toString()} className='mb-0' variant={`${copyCodeSuccess ? 'success' : 'info'}`} onClick={handleWidgetCopyCode}><i className={`material-symbols-outlined`} aria-hidden="true">{copyCodeSuccess ? 'done' : 'content_copy'}</i></Button>
                        </div>
                        <pre>{widgetCode}</pre>
                    </div>

                    {ReactAppAPIKeyChanges !== 'true' && (
                        <>
                            <div className="aioa_dashboard-widget-code-toggle-header">
                                <div className="aioa_dashboard-widget-code-toggle">
                                    <Form.Check
                                        type="checkbox"
                                        id="code-switch"
                                        label={`${t('Advance Code')}`}
                                        defaultChecked={codeToggle}
                                        onChange={() => setCodeToggle(!codeToggle)}
                                    />

                                    <Button variant="outline-primary" onClick={() => handleShowWidgetAdvanceCodeInfoModel()} size="sm">
                                        <i className="material-symbols-outlined" aria-hidden="true">info</i>
                                    </Button>

                                    <Modal show={advanceCodeInfoModel} onHide={handleHideWidgetAdvanceCodeInfoModel} centered>
                                        <Modal.Header closeButton>
                                            <Modal.Title>{t('Advance Code')}</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>{t('advance code info text')}</Modal.Body>
                                    </Modal>

                                </div>
                            </div>
                        </>
                    )}

                </Card.Body>
            </Card>

        </>
    )
}

export default CopyWidgetScriptCode;
