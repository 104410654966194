import { Card } from "react-bootstrap"
import { useSelector } from "react-redux";
import { StoreProptypes } from "../../PropTypes/StoreProptypes";
import parseISODate from "../../Helpers/parseISODate";
import { useTranslation } from "react-i18next";
import UpgradePlanList from "../PaymentButton/UpgradePlanList";

const FreeWidgetCurrentActivePlanInfo = () => { // only Normal user Plan Details

    // Functions
    const { t } = useTranslation();

    // Redux Data
    const { currentWebsite, user } = useSelector((store: StoreProptypes) => store);

    return (
        <>
            {currentWebsite && currentWebsite.current_package_only && currentWebsite.current_package_only.length > 0 && (
                <>
                    <Card className="aioa_dashboard-plan-info-box normal-user">
                        <Card.Body>
                            <div className="aioa_dashboard-plan-info-box-label">{t('Current Plan')}</div>
                            <div className="aioa_dashboard-plan-info-box-left">
                                <h4 className="text-primary mb-1">{currentWebsite.current_package_only[0].name} Plan</h4>
                            </div>
                            <div className="aioa_dashboard-plan-info-box-right">
                                <div className="aioa_dashboard-plan-info-box-price">
                                    {currentWebsite.current_package_only[0].subscr_interval === 'Y' ? (
                                        <>
                                            <strong className="original-price">${(currentWebsite.current_package_only[0].price).toFixed(2)}<span>/{t('Per Year')}</span></strong>
                                        </>
                                    ) : (
                                        <>
                                            <strong className="original-price">${(currentWebsite.current_package_only[0].price).toFixed(2)}<span>/{t('Per Month')}</span></strong>
                                        </>
                                    )}
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </>
            )}
        </>
    )
}

const FreeWidgetPlanInfo = () => {

    // Redux Data
    const { currentWebsite, user, currentWebsitePlanStatus } = useSelector((store: StoreProptypes) => store);

    return (
        <>
            {!currentWebsitePlanStatus.isExpired && (
                <>
                    <FreeWidgetCurrentActivePlanInfo />
                </>
            )}

            {currentWebsite && (
                <>

                    <Card className="aioa_dashboard-upgrade-plan-info-box">
                        <Card.Body>
                            <div className="aioa_dashboard-upgrade-plan-info-box-title">
                                <div className="h4 text-primary">Select Plan</div>
                                <div className="">Select your desire plan based on your website pageview</div>
                            </div>
                            <UpgradePlanList
                                activePlanType={1}
                                activePlanDomain={currentWebsite.domain}
                                activePlanInterval={'M'}
                                activePlanPrice={currentWebsite.current_package_only[0].package_monthly_price}
                            />
                        </Card.Body>
                    </Card>
                </>
            )}

        </>
    )
}

export default FreeWidgetPlanInfo;