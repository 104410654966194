import { useSelector } from 'react-redux';
import '../../Assets/scss/components/init-msg-block.scss';
import { StoreProptypes } from '../../PropTypes/StoreProptypes';
import CopyWidgetScriptCode from '../ScriptCode';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

const InitMsgBlock = () => {

    // Function
    const { t } = useTranslation();
    const location = useLocation();

    // Redux Data
    const { currentWebsite } = useSelector((store: StoreProptypes) => store);

    return (
        <>
            <div className='aioa_dashboard-init-msg-block'>
                {currentWebsite && (
                    <>
                        {currentWebsite && currentWebsite.widget_last_use_date === null ? (
                            <>
                                <div className='aioa_dashboard-init-msg-block-text text-success'>Once it will be verify it will show further setting alternatively follow following instruction to copy widget code on your website. We have emailed you instruction to install All in One Accessibility widget into your website.</div>
                                {currentWebsite && currentWebsite.current_package_only && currentWebsite.current_package_only.length > 0 && (
                                    <>
                                        <CopyWidgetScriptCode
                                            widgetAPIKey={currentWebsite.current_package_only[0].api_key}
                                            widgetPosition={currentWebsite.widget_position !== null ? currentWebsite.widget_position : `bottom_right`}
                                            widgetColor={currentWebsite.widget_color_code !== null ? currentWebsite.widget_color_code : `#420083`}
                                        />
                                    </>
                                )}
                            </>
                        ) : (
                            <>
                                <div className='aioa_dashboard-init-msg-block-text text-success text-center'>
                                    {t('Siteinit Msg')}

                                    {(location.pathname === '/front/addon-services/pdfs' || location.pathname === '/front/addon-services/pdfs/') && (
                                        <>
                                            <br/>
                                            <br/>
                                            {t('PDFinit Msg')}
                                        </>
                                    )}

                                </div>
                            </>
                        )}
                    </>
                )}
            </div >

        </>
    )
}

export default InitMsgBlock;