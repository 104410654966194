import { Navigate } from 'react-router-dom';

interface PropTypes {
  userid: number | null;
  children: React.ReactElement<any, any>
}

const ProtectedRoute = ({ userid, children }: PropTypes) => {
  if (!userid) {
    return <Navigate to="/front/login" replace />;
  }

  return children;
};

export default ProtectedRoute