import { useEffect, useState } from "react";
import "../Assets/scss/base.scss";
import { useSelector } from "react-redux";
import { StoreProptypes } from "../PropTypes/StoreProptypes";
import { useTranslation } from "react-i18next";

import LodingImg from "../Assets/img/icon-spinner.svg";
import ToastMessage from "../Components/ToastMessage";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { DomainDetailsService } from "../Services/DomainService/DomainDetails";
import { getUserDetailsService } from "../Services/UserAction/LoginActionService";
import { getPlanListService } from "../Services/PlanListService";
import { API_RECALL } from "../Actions/Types";

interface PropTypes {
    children: any
}

const MainPageTemplate = (prop: PropTypes) => {

    const { children } = prop;

    const { isLoading, apiRecall, translationLoading, toastMessage, currentUserStatus, user, currentWebsiteData, accessToken, currentWebsite, currentWebsitePlanList } = useSelector((state: StoreProptypes) => state);

    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const [fullScreenLoading, setFullScreenLoading] = useState<boolean>(false);

    useEffect(() => {
        //console.info("translationLoading", translationLoading);
        //console.info("isLoading", isLoading);

        if (isLoading || translationLoading) {
            setFullScreenLoading(true);
        } else {
            setFullScreenLoading(false);
        }
    }, [isLoading, translationLoading])

    // go to top
    const onTop = () => {
        window.scrollTo(0, 0);
    }
    useEffect(() => {
        onTop();
    }, []);

    useEffect(() => {
        if (currentWebsiteData === null || currentWebsitePlanList === null) {
            if (accessToken && currentWebsite) {

                getUserDetailsService(accessToken, dispatch, t).then((userRes) => {
                    if (userRes.status === 200) {
                        DomainDetailsService(accessToken, currentWebsite.id, dispatch, t).then((domainRes) => {
                            if (domainRes.status === 200) {
                                getPlanListService(accessToken, 2, dispatch);
                            }
                        })
                    }
                });
            }
        }
    }, []);

    // User Status Update
    useEffect(() => {
        if (user && currentUserStatus) {
            if (currentUserStatus.currentUserType === 3 && currentUserStatus.isInitUser) {

                const userStatusCondition1: boolean = (location.pathname.includes('/front/package') || location.pathname.includes('/front/web/package'));
                const userStatusCondition2: boolean = (location.pathname === '/front/edit-profile' || location.pathname === '/front/edit-profile/');
                const userStatusCondition3: boolean = (location.pathname === '/front/settings' || location.pathname === '/front/settings/');
                const userStatusCondition4: boolean = (location.pathname === '/front/settings' || location.pathname === '/front/settings/');
                const userStatusCondition5: boolean = (location.pathname === '/front/getting-started' || location.pathname === '/front/getting-started/');

                //console.info("userStatusCondition ==>", userStatusCondition1, userStatusCondition2, userStatusCondition3, userStatusCondition4, userStatusCondition5, (userStatusCondition1 || userStatusCondition2 || userStatusCondition3 || userStatusCondition4 || userStatusCondition5));

                if (userStatusCondition1 || userStatusCondition2 || userStatusCondition3 || userStatusCondition4 || userStatusCondition5) {

                } else {
                    navigate('/front/getting-started?user_update=true');
                }
            }
        }
    }, [user, currentUserStatus]);


    // API Call Base on Flag
    useEffect(() => {
        //console.info("API Recall UseEffect Call...");
        if (user) {
            //console.info("API Recall UseEffect Call..., Inside User IF Condition");
            if (apiRecall) {
                //console.info("API Recall UseEffect Call..., Inside Recall IF Condition");
                if (accessToken && currentWebsite) {
                    //console.info("API Recall UseEffect Call..., Inside accessToken && currentWebsite IF Condition");
                    getUserDetailsService(accessToken, dispatch, t).then(async (userRes) => {
                        if (userRes.status === 200) {
                            DomainDetailsService(accessToken, currentWebsite.id, dispatch, t).then(async (domainRes) => {
                                if (domainRes.status === 200) {
                                    getPlanListService(accessToken, 2, dispatch);
                                    // Loading
                                    dispatch({
                                        type: API_RECALL,
                                        payload: false,
                                    });
                                }
                            });
                        }
                    });
                }
            }
        } else {
            //console.info("API Recall UseEffect Call..., Inside else Condition and flag disable");
            dispatch({
                type: API_RECALL,
                payload: false,
            });
        }

    }, [apiRecall]);

    return (
        <>
            {fullScreenLoading && (
                <>
                    <div role="alert" className="aioa_dashboard-fullscreen-loader" aria-live='polite'>
                        <div className="aioa_dashboard-loader">
                            <img src={LodingImg} alt="" />
                            <span className="visually-hidden">{t("Loading page. Please Do not refresh the page")} </span>
                        </div>
                    </div>
                </>
            )}

            {toastMessage.toast_message !== null && (
                <>
                    <ToastMessage />
                </>
            )}

            <div className="aioa_dashboard-main-content">
                {children}
            </div>

        </>
    )
}

export default MainPageTemplate;