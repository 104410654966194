import { useDispatch, useSelector } from "react-redux";
import DashboardPageTitle from "../../Components/InnerPageTitle";
import DashboardPagesTemplate from "../../Template/DashboardPagesTemplate";
import { useTranslation } from "react-i18next";
import { StoreProptypes } from "../../PropTypes/StoreProptypes";
import { Accordion, Button, Col, Row } from "react-bootstrap";
import { useState } from "react";
import WidgetIconType from "../../Components/WidgetSettingsComponents/WidgetIconType";
import WidgetIconSize from "../../Components/WidgetSettingsComponents/WidgetIconSize";
import WidgetIconColor from "../../Components/WidgetSettingsComponents/WidgetIconColor";
import WidgetIconPosition from "../../Components/WidgetSettingsComponents/WidgetIconPosition";
import WidgetSize from "../../Components/WidgetSettingsComponents/WidgetSize";
import WidgetAccessibilityStatementLink from "../../Components/WidgetSettingsComponents/AccessibilityStatementLink";
import AnalyticsTrackingCode from "../../Components/WidgetSettingsComponents/AnalyticsTracking";
import updateWidgetSettingsService from "../../Services/UpdateWidgetSettingsService";
import WidgetIconSizeMobile from "../../Components/WidgetSettingsComponents/WidgetIconSizeMobile";

import "../../Assets/scss/pages/widget-settings.scss";
import "../../Assets/scss/components/widget-preview-layout.scss";
import WidgetLayoutMain from "../../Components/WidgetPreview";
import DashboardIframePagesTemplate from "../../Template/DashboardIframePagesTemplate";

const WebWidgetSettingsPage = () => {

  // Redux Data
  const { currentWebsite, accessToken, currentWebsitePlanStatus } = useSelector((store: StoreProptypes) => store);

  // Functions
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // State
  const [changeWidgetIconType, setChangeWidgetIconType] = useState<number>(1);
  const [changeWidgetIconSize, setChangeWidgetIconSize] = useState<number>(3);
  const [isChangeWidgetIconCustomSize, setIsChangeWidgetIconCustomSize] = useState<boolean>(false);
  const [changeWidgetCustomIconSize, setChangeWidgetCustomIconSize] = useState<number>(50);
  const [isShowCustomWidgetIconSizeMobile, setIsShowCustomWidgetIconSizeMobile] = useState<boolean>(false); // Not Include in API
  const [changeWidgetIconSizeMobile, setChangeWidgetIconSizeMobile] = useState<number>(3);
  const [isChangeWidgetIconCustomSizeMobile, setIsChangeWidgetIconCustomSizeMobile] = useState<boolean>(false);
  const [changeWidgetIconCustomSizeMobile, setChangeWidgetIconCustomSizeMobile] = useState<number>(20);
  const [changeWidgetIconColor, setChangeWidgetIconColor] = useState<string>('#420083');
  const [changeWidgetIconPosition, setChangeWidgetIconPosition] = useState<string>('bottom_right');

  const [isChangeWidgetIconCustomPosition, setIsChangeWidgetIconCustomPosition] = useState<boolean>(false);
  const [changeWidgetIconCustomLeftPosition, setChangeWidgetIconCustomLeftPosition] = useState<number | null>(null);
  const [changeWidgetIconCustomRightPosition, setChangeWidgetIconCustomRightPosition] = useState<number | null>(0);
  const [changeWidgetIconCustomTopPosition, setChangeWidgetIconCustomTopPosition] = useState<number | null>(null);
  const [changeWidgetIconCustomBottomPosition, setChangeWidgetIconCustomBottomPosition] = useState<number | null>(0);

  const [changeWidgetSize, setChangeWidgetSize] = useState<number>(0);
  const [changeWidgetAccessibilityStatementLink, setChangeWidgetAccessibilityStatementLink] = useState<string>('');
  const [changeGaID, setChangeGaID] = useState<string>('');
  const [changeAdobeID, setChangeAdobeID] = useState<string>('');

  const handleSubmitUpdateWidgetSettings = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (accessToken && currentWebsite) {
      updateWidgetSettingsService(
        accessToken,
        currentWebsite.id,
        dispatch,
        t,
        changeWidgetIconType,
        isChangeWidgetIconCustomSize,
        changeWidgetCustomIconSize,
        isShowCustomWidgetIconSizeMobile,
        changeWidgetIconSizeMobile,
        isChangeWidgetIconCustomSizeMobile,
        changeWidgetIconCustomSizeMobile,
        changeWidgetIconSize,
        changeWidgetIconColor,
        changeWidgetIconPosition,
        isChangeWidgetIconCustomPosition,
        changeWidgetIconCustomLeftPosition,
        changeWidgetIconCustomRightPosition,
        changeWidgetIconCustomTopPosition,
        changeWidgetIconCustomBottomPosition,
        changeWidgetSize,
        changeWidgetAccessibilityStatementLink,
        changeGaID,
        changeAdobeID
      )
    }

  }

  return (
    <>
      <DashboardIframePagesTemplate>
        <DashboardPageTitle
          pageTitle={`${t("Widget Settings")}`}
          subPageTitle={`Update your All in One Accessibility Widget Settings for ${currentWebsite?.domain}`}
        />
        <div className="aioa_dashboard-widget-settings aioa_dashboard-settings-with-preview-wrapper">
          <Row>

            <Col className="aioa-dashboard-widget-preview-settings-col aioa_dashboard-widget-settings-wrapper">
              <Accordion defaultActiveKey={['0', '1', '2', '3', '4', '5', '6']} alwaysOpen>

                <Accordion.Item eventKey="0">
                  <Accordion.Header className="h4">{t("Select Widget Icon Type")}</Accordion.Header>
                  <Accordion.Body>
                    <WidgetIconType setChangeWidgetIconType={setChangeWidgetIconType} changeWidgetIconColor={changeWidgetIconColor} />
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="1">
                  <Accordion.Header>{isShowCustomWidgetIconSizeMobile ? t('Select Widget Icon Size For Desktop') : t("Select Widget Icon Size")}</Accordion.Header>
                  <Accordion.Body>
                    <WidgetIconSize
                      setChangeWidgetIconSize={setChangeWidgetIconSize}
                      changeWidgetIconColor={changeWidgetIconColor}
                      setIsChangeWidgetIconCustomSize={setIsChangeWidgetIconCustomSize}
                      setChangeWidgetCustomIconSize={setChangeWidgetCustomIconSize}
                      isShowCustomWidgetIconSizeMobile={isShowCustomWidgetIconSizeMobile}
                      setIsShowCustomWidgetIconSizeMobile={setIsShowCustomWidgetIconSizeMobile}
                    />
                  </Accordion.Body>
                </Accordion.Item>

                {isShowCustomWidgetIconSizeMobile && (
                  <>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>{t("Select Widget Icon Size For Mobile")}</Accordion.Header>
                      <Accordion.Body>
                        <WidgetIconSizeMobile
                          setChangeWidgetIconSizeMobile={setChangeWidgetIconSizeMobile}
                          setIsChangeWidgetIconCustomSizeMobile={setIsChangeWidgetIconCustomSizeMobile}
                          setChangeWidgetIconCustomSizeMobile={setChangeWidgetIconCustomSizeMobile}
                        />
                      </Accordion.Body>
                    </Accordion.Item>
                  </>
                )}

                <Accordion.Item eventKey="2">
                  <Accordion.Header>{t("Select Widget Color")}</Accordion.Header>
                  <Accordion.Body>
                    <WidgetIconColor setChangeWidgetIconColor={setChangeWidgetIconColor} changeWidgetIconColor={changeWidgetIconColor} />
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="3">
                  <Accordion.Header>{t("Select Widget Position")}</Accordion.Header>
                  <Accordion.Body>
                    <WidgetIconPosition
                      setChangeWidgetIconPosition={setChangeWidgetIconPosition}
                      setIsChangeWidgetIconCustomPosition={setIsChangeWidgetIconCustomPosition}
                      setChangeWidgetIconCustomLeftPosition={setChangeWidgetIconCustomLeftPosition}
                      setChangeWidgetIconCustomRightPosition={setChangeWidgetIconCustomRightPosition}
                      setChangeWidgetIconCustomTopPosition={setChangeWidgetIconCustomTopPosition}
                      setChangeWidgetIconCustomBottomPosition={setChangeWidgetIconCustomBottomPosition}
                    />
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="4">
                  <Accordion.Header>{t("widget size label")}</Accordion.Header>
                  <Accordion.Body>
                    <WidgetSize setChangeWidgetSize={setChangeWidgetSize} />
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="5">
                  <Accordion.Header>{t("accessibility statement link label")}</Accordion.Header>
                  <Accordion.Body>
                    <WidgetAccessibilityStatementLink setChangeWidgetAccessibilityStatementLink={setChangeWidgetAccessibilityStatementLink} />
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="6">
                  <Accordion.Header>{t("Analytics Tracking")}</Accordion.Header>
                  <Accordion.Body>
                    <AnalyticsTrackingCode setChangeAdobeID={setChangeAdobeID} setChangeGaID={setChangeGaID} />
                  </Accordion.Body>
                </Accordion.Item>

                {((!currentWebsitePlanStatus.isExpired) || (currentWebsitePlanStatus.planExpiredData !== null || currentWebsitePlanStatus.planName !== null)) && (
                  <>
                    <div className="aioa_dashboard-widget-settings-screen-btns">
                      <Button
                        type="submit"
                        variant="primary"
                        size="lg"
                        className="mb-0"
                        onClick={(e) => handleSubmitUpdateWidgetSettings(e)}
                      >
                        {t("Save Settings")}
                      </Button>

                      <Button
                        variant="secondary"
                        size="lg"
                        className={`widget-preview-btn d-xl-none`}
                      //onClick={widgetPreviewPopupShow}
                      >
                        {t(`Widget Preview`)}
                      </Button>
                    </div>
                  </>
                )}

              </Accordion>
            </Col>

            <Col className="aioa-dashboard-widget-preview-col web-iframe-preview">
                <WidgetLayoutMain 
                  changeWidgetIconType={changeWidgetIconType}
                  changeWidgetIconSize={changeWidgetIconSize}
                  isChangeWidgetIconCustomSize={isChangeWidgetIconCustomSize}
                  changeWidgetCustomIconSize={changeWidgetCustomIconSize}
                  changeWidgetIconColor={changeWidgetIconColor}
                  changeWidgetIconPosition={changeWidgetIconPosition}
                  isChangeWidgetIconCustomPosition={isChangeWidgetIconCustomPosition}
                  changeWidgetIconCustomLeftPosition={changeWidgetIconCustomLeftPosition}
                  changeWidgetIconCustomRightPosition={changeWidgetIconCustomRightPosition}
                  changeWidgetIconCustomTopPosition={changeWidgetIconCustomTopPosition}
                  changeWidgetIconCustomBottomPosition={changeWidgetIconCustomBottomPosition}
                  changeWidgetSize={changeWidgetSize}
                  isShowIconPreview={true}
                />
            </Col>
          </Row>
        </div>
      </DashboardIframePagesTemplate>

    </>
  )
}

export default WebWidgetSettingsPage;