import {LOADING, LOGIN_SUCCESS, LOGOUT, SET_ACCESS_TOKEN, SET_CURRENT_USER_STATUS, SET_CURRENT_WEBSITE, SET_MESSAGE, SET_WEBSITE_LIST} from "../../Actions/Types";
import { AutoLoginApi } from "../../Api/UserAction/AutoLoginApi";
import decodeBase64 from "../../Helpers/decodeBase64";
import encodeBase64 from "../../Helpers/encodeBase64";
import { WebsiteListPropTypes } from "../../PropTypes/WebsitePropTypes";
import { DomainDetailsService } from "../DomainService/DomainDetails";
import { getPlanListService } from "../PlanListService";
import { getUserDetailsService } from "./LoginActionService";

const autoLoginService = (
    token: string,
    dispatch: Function,
    navigate: Function,
    t: Function,
    activeLanguageCodeString?: string
) => {
    // Loading
    dispatch({
        type: LOADING,
        payload: true,
    });

    // Token action
    const newToken = decodeBase64(token).split("|");
    const newTokenWebsiteTemp = newToken[0].replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('/')[0];
    const newTokenWebsite = newTokenWebsiteTemp.toLocaleLowerCase();
    const dist = newToken[1];

    let userId;
    let newPID: string;
    let newInterval: string;
    let newUserID: string;
    let newPlanInterval: string;
    let newiFrameDist: string;

    if (newTokenWebsite === '-' && newToken.length > 2) {
        userId = newToken[2];
    } else {
        newPID = newToken[2];
        newInterval = newToken[3];
        newUserID = newToken[4];
        newPlanInterval = newToken[5];
        newiFrameDist = newToken[6];
    }


    AutoLoginApi(encodeBase64(newToken[0]), userId).then((autoLoginRes) => {

        // Auto Login Res
        if (autoLoginRes.Data && autoLoginRes.token) {

            // Current User Plans
            getPlanListService(autoLoginRes.token, 2, dispatch);

            let currentUserType: number;

            if (autoLoginRes.Data.is_extension_user === 1) {
                currentUserType = 5
            }
            else if (autoLoginRes.Data.department === 4) {
                if (autoLoginRes.Data.is_multiple_domain === 1) {
                    currentUserType = 3
                } else {
                    currentUserType = 2
                }
            }
            else if (autoLoginRes.Data.is_multiple_domain === 2) {
                currentUserType = 4
            }
            else {
                currentUserType = 1
            }

            // Set User Status
            dispatch({
                type: SET_CURRENT_USER_STATUS,
                payload: {
                    currentUserType: currentUserType,
                    isInitUser: autoLoginRes.Data.website_lists.length === 0 ? true : false
                },
            });

            // Save User token
            dispatch({
                type: SET_ACCESS_TOKEN,
                payload: autoLoginRes.token,
            });

            // Save User Data
            dispatch({
                type: LOGIN_SUCCESS,
                payload: { user: autoLoginRes.Data },
            });

            // Set Website List
            dispatch({
                type: SET_WEBSITE_LIST,
                payload: autoLoginRes.Data.website_lists
            });

            // Set Current website ID
            let currentWebsiteID: number;

            if( autoLoginRes.Data.website_lists.length>0) {
                if (newTokenWebsite && newTokenWebsite !== '-') {
                    const tempCurrentWebsiteID = (autoLoginRes.Data.website_lists).filter((domainFilterData: WebsiteListPropTypes) => ((domainFilterData.domain).toLocaleLowerCase()) === newTokenWebsite).map((filterObj: WebsiteListPropTypes) => {
                        return filterObj.id
                    })
                    currentWebsiteID = tempCurrentWebsiteID[0];
                    //console.log("inside if  ==>", currentWebsiteID);
                } else {
                    currentWebsiteID = autoLoginRes.Data.website_lists[0].id;
                }

                DomainDetailsService(autoLoginRes.token, currentWebsiteID, dispatch, t).then((res) => {
                    if (res.status === 200) {
                        //console.info("dist=>",dist);
                        // Domain Response
                        if (dist && dist !== '0') {
                            if (dist === '1') {
                                // Code Screen
                                navigate("/front/scripts");

                            }
                            else if (dist === '2') {
                                if (autoLoginRes.current_plan_id) {
                                    const pId = autoLoginRes.current_plan_id;
                                    const urlString = `${pId}|${newTokenWebsite}|Y|${autoLoginRes.Data.id}|${activeLanguageCodeString ? activeLanguageCodeString : `en`}|1`;
                                    const planEncodedString1 = encodeBase64(urlString);
                                    // Checkout
                                    navigate(`/front/package/${planEncodedString1}`);
                                }
                            }
                            else if (dist === 'uc') {
                                const pId = autoLoginRes.current_plan_id;
                                const urlString = `${newPID}|${newTokenWebsite}|${newPlanInterval}|${autoLoginRes.Data.id}|${newPlanInterval}|7`;
                                const planEncodedString1 = encodeBase64(urlString);
                                navigate(`/front/package/${planEncodedString1}`);
                            }
                            else if (dist === '15') {
                                if (autoLoginRes.current_plan_id) {
                                    const pId = autoLoginRes.current_plan_id;
                                    const urlString = `${pId}|${newTokenWebsite}|Y|${autoLoginRes.Data.id}|${activeLanguageCodeString ? activeLanguageCodeString : `en`}|5`;
                                    const planEncodedString1 = encodeBase64(urlString);
                                    // Checkout
                                    navigate(`/front/package/${planEncodedString1}`);
                                }
                            }
                            else if (dist === '3') {
                                // order screen
                                navigate(`/front/orders?is_new=true&status=success&wtoken=${encodeBase64(currentWebsiteID.toString())}`);

                            }
                            else if (dist === '5') {
                                // order screen
                                navigate("/front/widget-settings");

                            }
                            else if (dist === '6' && autoLoginRes.Data.department === 4) {
                                // order screen
                                navigate("/front");
                                getUserDetailsService(autoLoginRes.token, dispatch, t).then((userRes) => {
                                    if(userRes.status === 200) {
                                        navigate('/front')
                                    }
                                })

                            }
                            else if (dist === '7') {
                                navigate('/front/websites')
                                getUserDetailsService(autoLoginRes.token, dispatch, t).then((userRes) => {
                                    if(userRes.status === 200) {
                                        navigate('/front/websites')
                                    }
                                })
                            }
                            else if (dist === '004') {
                                navigate("/front/websites");
                            }
                            else if (dist === '007') {
                                navigate("/front/web-widget-settings");
                            }
                            else if (dist === '008') {
                                //console.info("my-scan call...");
                                navigate("/front/my-scan");
                            }
                            else if (dist === 'ad1') {
                                navigate("/front/addon-services/white-label-service");
                            }
                            else if (dist === 'ad2') {
                                navigate("/front/addon-services/custom-branding-service");
                            }
                            else if (dist === 'ad3') {
                                navigate("/front/addon-services/modify-accessibility-menu");
                            }
                            else if (dist === 'ad4') {
                                navigate("/front/addon-services/live-site-translation");
                            }
                            else if (dist === 'ad5') {
                                navigate("/front/addon-services/pdfs");
                            }
                            else if (dist === 'ad6') {
                                navigate("/front/addon-services/manual-audit-report");
                            } else if (dist === 'ad7') {
                                navigate("/front/addon-services/vpat-report");
                            } else if (dist === 'ad8') {
                                navigate("/front/addon-services/manual-remediation");
                            } else if (dist === 'ad9') {
                                navigate("/front/addon-services/design-audit");
                            } else if (dist === 'ad10') {
                                navigate("/front/addon-services/native-mobile-app-audit");
                            } else if (dist === 'ad11') {
                                navigate("/front/addon-services/web-app-spa-audit");
                            } else {

                            }
                        } else {
                            // Navigation
                            navigate("/front");
                        }


                        // Loading
                        dispatch({
                            type: LOADING,
                            payload: false,
                        });

                        // Res Msg
                        dispatch({
                            type: SET_MESSAGE,
                            payload: {
                                "toast_message": t('Login Success Msg'),
                                "toast_type": "success",
                                "onclose": null
                            }
                        })

                    } else {

                        // Loading
                        dispatch({
                            type: LOADING,
                            payload: false,
                        });

                    }

                });
            }
            else{
                    navigate(`/front/getting-started?user_update=true`);
                    // Set Website List
                    dispatch({
                        type: SET_WEBSITE_LIST,
                        payload: null
                    });

                    // Set Current Website Data
                    dispatch({
                        type: SET_CURRENT_WEBSITE,
                        payload: null
                    });

                    // Set Loading
                    dispatch({
                        type: LOADING,
                        payload: false,
                    });
                }


        } else {

            // Logout
            dispatch({
                type: LOGOUT,
            });

            navigate("/front/login");

            // Loading
            dispatch({
                type: LOADING,
                payload: false,
            });

            // Res Msg
            dispatch({
                type: SET_MESSAGE,
                payload: {
                    "toast_message": t('Login Fail Msg'),
                    "toast_type": "danger",
                    "onclose": null
                }
            });

        }

    })

}

export { autoLoginService }
