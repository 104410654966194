import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'react-bootstrap';

import '../../Assets/scss/components/sidebar-navigation.scss';
import { StoreProptypes } from '../../PropTypes/StoreProptypes';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import SupportButtonIcon from "../../Assets/img/icon-customer-service.svg";

interface PropTypes {
    handelMobileMenu: Function
}

const DashboardSidebarNavigation = (props: PropTypes) => {

    // 
    const { handelMobileMenu } = props;

    // Functions
    const { t } = useTranslation();
    const location = useLocation();

    // Redux Data
    const { currentWebsite, currentWebsitePlanStatus, user } = useSelector((store: StoreProptypes) => store);

    // State
    const [isDisableMenuItem, setIsDisableMenuItem] = useState<boolean>(false);
    const [dropdownToggle, setDropdownToggle] = useState(false);

    // Check isDisableMenuItem
    useEffect(() => {
        if ((user && user.department !== 1) && currentWebsitePlanStatus.isExpired || (currentWebsitePlanStatus.planExpiredData === null && currentWebsitePlanStatus.planName === null)) {
            setIsDisableMenuItem(true);
        } else {
            setIsDisableMenuItem(false);
        }
    }, [currentWebsite]);

    useEffect(() => {
        let condition = location.pathname === '/front/addon-services/white-label-service' || location.pathname === '/front/addon-services/white-label-service/' || location.pathname === '/front/addon-services/modify-accessibility-menu' || location.pathname === '/front/addon-services/modify-accessibility-menu/' || location.pathname === '/front/addon-services/live-site-translation' || location.pathname === '/front/addon-services/live-site-translation/' || location.pathname === '/front/addon-services/pdfs' || location.pathname === '/front/addon-services/pdfs/' || location.pathname === '/front/addon-services/manual-audit-report' || location.pathname === '/front/addon-services/manual-audit-report/' || location.pathname === '/front/addon-services/vpat-report' || location.pathname === '/front/addon-services/vpat-report/' || location.pathname === '/front/addon-services/manual-remediation' || location.pathname === '/front/addon-services/manual-remediation/' || location.pathname === '/front/addon-services/design-audit' || location.pathname === '/front/addon-services/design-audit/' || location.pathname === '/front/addon-services/native-mobile-app-audit' || location.pathname === '/front/addon-services/native-mobile-app-audit/' || location.pathname === '/front/addon-services/web-app-spa-audit' || location.pathname === '/front/addon-services/web-app-spa-audit/' || location.pathname === '/front/addon-services/custom-branding-service' || location.pathname === '/front/addon-services/custom-branding-service/';
        if (condition) {
            setDropdownToggle(true);
        } else {
            setDropdownToggle(false);
        }
    }, [])

    return (
        <>
            <nav className='aioa_dashboard-sidebar-nav'>
                <ul>
                    {user && user.department === 4 ? ( // Partner user
                        <>
                            <li>
                                <Link onClick={() => handelMobileMenu()} to="/front/manage-plan" className={`${location.pathname === '/front/manage-plan' ? `active` : ``}`}>
                                    <div className="nav-link-icon"><i className="material-symbols-outlined" aria-hidden="true">money</i></div>
                                    <div className="nav-link-text">{t("Manage Plan")}</div>
                                </Link>
                            </li>

                            <li className={`nav-item`}>
                                <>
                                    <Dropdown
                                        autoClose={false}
                                        show={dropdownToggle}
                                        onToggle={() => setDropdownToggle(!dropdownToggle)}
                                    >

                                        <Dropdown.Toggle id="addon-dropdown" className={`addon-dropdown ${dropdownToggle ? `active` : ``}`}>
                                            <div className={`nav-link-icon`}><i className="material-symbols-outlined" aria-hidden="true">accessibility_new</i></div>
                                            <div className={`nav-link-text`}>{t("Accessibility Add-On Services")}</div>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu className='addon-dropdown-menu'>

                                            <Dropdown.Item as={`div`}>
                                                <Link onClick={() => handelMobileMenu()} to="/front/addon-services/white-label-service" className={`${location.pathname === '/front/addon-services/white-label-service' ? `active` : ``}`}>
                                                    <div className="nav-link-text">{t("White Label")}</div>
                                                </Link>
                                            </Dropdown.Item>

                                            <Dropdown.Item as={`div`}>
                                                <Link onClick={() => handelMobileMenu()} className={`${(location.pathname === '/front/addon-services/custom-branding-service' || location.pathname === '/front/addon-services/custom-branding-service/') ? `active` : ``}`} to="/front/addon-services/custom-branding-service">
                                                    <div className="nav-link-text">{t("Custom Branding")}</div>
                                                </Link>
                                            </Dropdown.Item>

                                            <Dropdown.Item as={`div`}>
                                                <Link onClick={() => handelMobileMenu()} to="/front/addon-services/modify-accessibility-menu" className={`${(location.pathname === '/front/addon-services/modify-accessibility-menu' || location.pathname === '/front/addon-services/modify-accessibility-menu/') ? `active` : ``}`}>
                                                    <div className="nav-link-text">{t("Modify Accessibility Menu")}</div>
                                                </Link>
                                            </Dropdown.Item>

                                            <Dropdown.Item as={`div`}>
                                                <Link onClick={() => handelMobileMenu()} className={`${(location.pathname === '/front/addon-services/live-site-translation' || location.pathname === '/front/addon-services/live-site-translation/') ? `active text-primary` : ``}`} to="/front/addon-services/live-site-translation">
                                                    <div className="nav-link-text">{t("Live Site Translation")}</div>
                                                </Link>
                                            </Dropdown.Item>

                                            {!(currentWebsitePlanStatus.isExpired) && (
                                                <>
                                                    <Dropdown.Item as={`div`}>
                                                        <Link onClick={() => handelMobileMenu()} className={`${(location.pathname === '/front/addon-services/pdfs/' || location.pathname === '/front/addon-services/pdfs') ? `active text-primary` : ``}`} to="/front/addon-services/pdfs">
                                                            <div className="nav-link-text">{t("Documents & PDFs")}</div>
                                                        </Link>
                                                    </Dropdown.Item>
                                                </>
                                            )}

                                            <Dropdown.Item as={`div`}>
                                                <Link onClick={() => handelMobileMenu()} className={`${(location.pathname === '/front/addon-services/manual-audit-report' || location.pathname === '/front/addon-services/manual-audit-report/') ? `active text-primary` : ``}`} to="/front/addon-services/manual-audit-report">
                                                    <div className="nav-link-text">{t("Manual Audit Report")}</div>
                                                </Link>
                                            </Dropdown.Item>

                                            <Dropdown.Item as={`div`}>
                                                <Link onClick={() => handelMobileMenu()} className={`${(location.pathname === '/front/addon-services/vpat-report' || location.pathname === '/front/addon-services/vpat-report/') ? `active text-primary` : ``}`} to="/front/addon-services/vpat-report">
                                                    <div className="nav-link-text">{t("VPAT Report")}</div>
                                                </Link>
                                            </Dropdown.Item>

                                            <Dropdown.Item as={`div`}>
                                                <Link onClick={() => handelMobileMenu()} className={`${(location.pathname === '/front/addon-services/manual-remediation' || location.pathname === '/front/addon-services/manual-remediation/') ? `active text-primary` : ``}`} to="/front/addon-services/manual-remediation">
                                                    <div className="nav-link-text">{t("Manual Remediation")}</div>
                                                </Link>
                                            </Dropdown.Item>

                                            <Dropdown.Item as={`div`}>
                                                <Link onClick={() => handelMobileMenu()} className={`${(location.pathname === '/front/addon-services/design-audit' || location.pathname === '/front/addon-services/design-audit/') ? `active text-primary` : ``}`} to="/front/addon-services/design-audit">
                                                    <div className="nav-link-text">{t("Design Audit")}</div>
                                                </Link>
                                            </Dropdown.Item>

                                            <Dropdown.Item as={`div`}>
                                                <Link onClick={() => handelMobileMenu()} className={`${(location.pathname === '/front/addon-services/native-mobile-app-audit' || location.pathname === '/front/addon-services/native-mobile-app-audit/') ? `active text-primary` : ``}`} to="/front/addon-services/native-mobile-app-audit">
                                                    <div className="nav-link-text">{t("Native Mobile App Audit")}</div>
                                                </Link>
                                            </Dropdown.Item>

                                            <Dropdown.Item as={`div`}>
                                                <Link onClick={() => handelMobileMenu()} className={`${(location.pathname === '/front/addon-services/web-app-spa-audit' || location.pathname === '/front/addon-services/web-app-spa-audit/') ? `active text-primary` : ``}`} to="/front/addon-services/web-app-spa-audit">
                                                    <div className="nav-link-text">{t("Web App-SPA Audit")}</div>
                                                </Link>
                                            </Dropdown.Item>

                                        </Dropdown.Menu>
                                    </Dropdown>
                                </>
                            </li>

                            <li>
                                <Link onClick={() => handelMobileMenu()} to="/front/orders" className={`${location.pathname === '/front/orders' ? `active` : ``}`}>
                                    <div className="nav-link-icon"><i className="material-symbols-outlined" aria-hidden="true">list_alt</i></div>
                                    <div className="nav-link-text">{t("Orders")}</div>
                                </Link>
                            </li>

                            <li>
                                <Link onClick={() => handelMobileMenu()} to="/front/widget-settings" className={`${location.pathname === '/front/widget-settings' ? `active` : ``}`}>
                                    <div className="nav-link-icon"><i className="material-symbols-outlined" aria-hidden="true">settings</i></div>
                                    <div className="nav-link-text">{t("Widget Settings")}</div>
                                </Link>
                            </li>

                            <li>
                                <Link onClick={() => handelMobileMenu()} to="/front/scripts" tabIndex={isDisableMenuItem ? -1 : 0} aria-hidden={isDisableMenuItem ? true : false} className={`${isDisableMenuItem ? `disabled` : ``} ${location.pathname === '/front/scripts' ? `active` : ``}`} >
                                    <div className="nav-link-icon"><i className="material-symbols-outlined" aria-hidden="true">code</i></div>
                                    <div className="nav-link-text">{t("Scripts")}</div>
                                </Link>
                            </li>

                            <li>
                                <Link onClick={() => handelMobileMenu()} to="/front/domain-report" className={`${location.pathname === '/front/domain-report' ? `active` : ``}`} >
                                    <div className="nav-link-icon"><i className="material-symbols-outlined" aria-hidden="true">insert_chart</i></div>
                                    <div className="nav-link-text">{t("Domain Report")}</div>
                                </Link>
                            </li>

                            <li>
                                <Link onClick={() => handelMobileMenu()} to="/front/manage-image-alt-text" className={`${location.pathname === '/front/manage-image-alt-text' ? `active` : ``}`}>
                                    <div className="nav-link-icon"><i className="material-symbols-outlined" aria-hidden="true">image</i></div>
                                    <div className="nav-link-text">{t("Image Alt Text Remediation")}</div>
                                </Link>
                            </li>

                        </>
                    ) : ( // Normal user Menu
                        <>
                            <li>
                                <Link onClick={() => handelMobileMenu()} to="/front/scripts" tabIndex={isDisableMenuItem ? -1 : 0} aria-hidden={isDisableMenuItem ? true : false} className={`${isDisableMenuItem ? `disabled` : ``} ${location.pathname === '/front/scripts' ? `active` : ``}`} >
                                    <div className="nav-link-icon"><i className="material-symbols-outlined" aria-hidden="true">code</i></div>
                                    <div className="nav-link-text">{t("Scripts")}</div>
                                </Link>
                            </li>

                            <li>
                                <Link onClick={() => handelMobileMenu()} to="/front" tabIndex={isDisableMenuItem ? -1 : 0} aria-hidden={isDisableMenuItem ? true : false} className={`${isDisableMenuItem ? `disabled` : ``} ${location.pathname === '/front' ? `active` : ``}`}>
                                    <div className="nav-link-icon"><i className="material-symbols-outlined" aria-hidden="true">dashboard</i></div>
                                    <div className="nav-link-text">{t("Dashboard")}</div>
                                </Link>
                            </li>

                            <li>
                                <Link onClick={() => handelMobileMenu()} to="/front/widget-settings" tabIndex={isDisableMenuItem ? -1 : 0} aria-hidden={isDisableMenuItem ? true : false} className={`${isDisableMenuItem ? `disabled` : ``} ${location.pathname === '/front/widget-settings' ? `active` : ``}`}>
                                    <div className="nav-link-icon"><i className="material-symbols-outlined" aria-hidden="true">settings</i></div>
                                    <div className="nav-link-text">{t("Widget Settings")}</div>
                                </Link>
                            </li>

                            <li>
                                <Link onClick={() => handelMobileMenu()} to="/front/websites" className={`${location.pathname === '/front/websites' ? `active` : ``}`}>
                                    <div className="nav-link-icon"><i className="material-symbols-outlined" aria-hidden="true">public</i></div>
                                    <div className="nav-link-text">{t("My Websites")}</div>
                                </Link>
                            </li>

                            <li>
                                <Link onClick={() => handelMobileMenu()} to="/front/manage-image-alt-text" tabIndex={isDisableMenuItem ? -1 : 0} aria-hidden={isDisableMenuItem ? true : false} className={`${isDisableMenuItem ? `disabled` : ``} ${location.pathname === '/front/manage-image-alt-text' ? `active` : ``}`}>
                                    <div className="nav-link-icon"><i className="material-symbols-outlined" aria-hidden="true">image</i></div>
                                    <div className="nav-link-text">{t("Image Alt Text Remediation")}</div>
                                </Link>
                            </li>

                            <li className={`nav-item`}>
                                <>
                                    <Dropdown
                                        autoClose={false}
                                        show={dropdownToggle}
                                        onToggle={() => setDropdownToggle(!dropdownToggle)}
                                    >

                                        <Dropdown.Toggle id="addon-dropdown" className={`addon-dropdown ${dropdownToggle ? `active` : ``}`}>
                                            <div className={`nav-link-icon`}><i className="material-symbols-outlined" aria-hidden="true">accessibility_new</i></div>
                                            <div className={`nav-link-text`}>{t("Accessibility Add-On Services")}</div>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu className='addon-dropdown-menu'>

                                            <Dropdown.Item as={`div`}>
                                                <Link onClick={() => handelMobileMenu()} to="/front/addon-services/white-label-service" tabIndex={isDisableMenuItem ? -1 : 0} aria-hidden={isDisableMenuItem ? true : false} className={`${isDisableMenuItem ? `disabled` : ``} ${location.pathname === '/front/addon-services/white-label-service' ? `active` : ``}`}>
                                                    <div className="nav-link-text">{t("White Label")}</div>
                                                </Link>
                                            </Dropdown.Item>

                                            <Dropdown.Item as={`div`}>
                                                <Link onClick={() => handelMobileMenu()} to="/front/addon-services/modify-accessibility-menu" tabIndex={isDisableMenuItem ? -1 : 0} aria-hidden={isDisableMenuItem ? true : false} className={`${isDisableMenuItem ? `disabled` : ``} ${(location.pathname === '/front/addon-services/modify-accessibility-menu' || location.pathname === '/front/addon-services/modify-accessibility-menu/') ? `active` : ``}`}>
                                                    <div className="nav-link-text">{t("Modify Accessibility Menu")}</div>
                                                </Link>
                                            </Dropdown.Item>

                                            <Dropdown.Item as={`div`}>
                                                <Link onClick={() => handelMobileMenu()} tabIndex={isDisableMenuItem ? -1 : 0} aria-hidden={isDisableMenuItem ? true : false} className={`${isDisableMenuItem ? `disabled` : ``} ${(location.pathname === '/front/addon-services/live-site-translation' || location.pathname === '/front/addon-services/live-site-translation/') ? `active text-primary` : ``}`} to="/front/addon-services/live-site-translation">
                                                    <div className="nav-link-text">{t("Live Site Translation")}</div>
                                                </Link>
                                            </Dropdown.Item>

                                            <Dropdown.Item as={`div`}>
                                                <Link onClick={() => handelMobileMenu()} tabIndex={isDisableMenuItem ? -1 : 0} aria-hidden={isDisableMenuItem ? true : false} className={`${isDisableMenuItem ? `disabled` : ``} ${(location.pathname === '/front/addon-services/pdfs/' || location.pathname === '/front/addon-services/pdfs') ? `active text-primary` : ``}`} to="/front/addon-services/pdfs">
                                                    <div className="nav-link-text">{t("Documents & PDFs")}</div>
                                                </Link>
                                            </Dropdown.Item>

                                            <Dropdown.Item as={`div`}>
                                                <Link onClick={() => handelMobileMenu()} className={`${(location.pathname === '/front/addon-services/manual-audit-report' || location.pathname === '/front/addon-services/manual-audit-report/') ? `active text-primary` : ``}`} to="/front/addon-services/manual-audit-report">
                                                    <div className="nav-link-text">{t("Manual Audit Report")}</div>
                                                </Link>
                                            </Dropdown.Item>

                                            <Dropdown.Item as={`div`}>
                                                <Link onClick={() => handelMobileMenu()} className={`${(location.pathname === '/front/addon-services/vpat-report' || location.pathname === '/front/addon-services/vpat-report/') ? `active text-primary` : ``}`} to="/front/addon-services/vpat-report">
                                                    <div className="nav-link-text">{t("VPAT Report")}</div>
                                                </Link>
                                            </Dropdown.Item>

                                            <Dropdown.Item as={`div`}>
                                                <Link onClick={() => handelMobileMenu()} className={`${(location.pathname === '/front/addon-services/manual-remediation' || location.pathname === '/front/addon-services/manual-remediation/') ? `active text-primary` : ``}`} to="/front/addon-services/manual-remediation">
                                                    <div className="nav-link-text">{t("Manual Remediation")}</div>
                                                </Link>
                                            </Dropdown.Item>

                                            <Dropdown.Item as={`div`}>
                                                <Link onClick={() => handelMobileMenu()} className={`${(location.pathname === '/front/addon-services/design-audit' || location.pathname === '/front/addon-services/design-audit/') ? `active text-primary` : ``}`} to="/front/addon-services/design-audit">
                                                    <div className="nav-link-text">{t("Design Audit")}</div>
                                                </Link>
                                            </Dropdown.Item>

                                            <Dropdown.Item as={`div`}>
                                                <Link onClick={() => handelMobileMenu()} className={`${(location.pathname === '/front/addon-services/native-mobile-app-audit' || location.pathname === '/front/addon-services/native-mobile-app-audit/') ? `active text-primary` : ``}`} to="/front/addon-services/native-mobile-app-audit">
                                                    <div className="nav-link-text">{t("Native Mobile App Audit")}</div>
                                                </Link>
                                            </Dropdown.Item>

                                            <Dropdown.Item as={`div`}>
                                                <Link onClick={() => handelMobileMenu()} className={`${(location.pathname === '/front/addon-services/web-app-spa-audit' || location.pathname === '/front/addon-services/web-app-spa-audit/') ? `active text-primary` : ``}`} to="/front/addon-services/web-app-spa-audit">
                                                    <div className="nav-link-text">{t("Web App-SPA Audit")}</div>
                                                </Link>
                                            </Dropdown.Item>

                                        </Dropdown.Menu>
                                    </Dropdown>
                                </>
                            </li>

                            <li>
                                <Link onClick={() => handelMobileMenu()} to="/front/manage-plan" className={`${location.pathname === '/front/manage-plan' ? `active` : ``}`}>
                                    <div className="nav-link-icon"><i className="material-symbols-outlined" aria-hidden="true">money</i></div>
                                    <div className="nav-link-text">{t("Manage Plan")}</div>
                                </Link>
                            </li>

                            <li>
                                <Link onClick={() => handelMobileMenu()} to="/front/orders" className={`${location.pathname === '/front/orders' ? `active` : ``}`}>
                                    <div className="nav-link-icon"><i className="material-symbols-outlined" aria-hidden="true">list_alt</i></div>
                                    <div className="nav-link-text">{t("Orders")}</div>
                                </Link>
                            </li>

                        </>
                    )}

                    <li className='support-btn'>
                        <a 
                            target={`_blank`} href="https://www.skynettechnologies.com/report-accessibility-problem"
                            onClick={() => handelMobileMenu()}
                        >
                            <div className="nav-link-icon">
                                <img src={SupportButtonIcon} alt="" aria-hidden="true" />
                            </div>
                            <div className="nav-link-text">{t("Support")}</div>
                        </a>
                    </li>

                </ul>
            </nav>
        </>
    )
}

export default DashboardSidebarNavigation;