import {
  REGISTER_SUCCESS,
  LOGIN_SUCCESS,
  LOGOUT,
  LOADING,
  TRANSLATION_LOADING,
  SET_LANGUAGE_LIST,
  SET_ACTIVE_LANGUAGE,
  SET_TRANSLATIONS,
  SET_ACCESS_TOKEN,
  UPDATE_USER,
  SET_MESSAGE,
  CLEAR_MESSAGE,
  SET_CURRENT_WEBSITE,
  SET_WEBSITE_LIST,
  SET_CURRENT_WEBSITE_DATA,
  SET_LANGUAGE_KEYWORDS_LIST,
  SET_CURRENT_WEBSITE_PLAN_DATA,
  SET_CURRENT_WEBSITE_PLAN_LIST,
  SET_CURRENT_USER_STATUS,
  SET_CURRENT_USER_DISCOUNT,
  API_RECALL
} from "../Actions/Types";

const CombinedReducer = (state: any, action: any) => {
  const { type, payload } = action;

  //console.info("payload ==>", payload);

  switch (type) {
    case REGISTER_SUCCESS:
      return {
        ...state,
        user: payload.user,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        user: payload.user,
      };
    case SET_CURRENT_USER_STATUS:
      return {
        ...state,
        currentUserStatus: payload,
      };
    case SET_CURRENT_USER_DISCOUNT:
      return {
        ...state,
        currentUserDiscount: payload,
      };
    case UPDATE_USER:
      return {
        ...state,
        user: payload.user,
      };

    case LOGOUT:
      return {
        ...state,
        user: null,
        currentUserStatus: {
          currentUserType: null,
          isInitUser: false
        },
        //activeLanguage: null,
        //languageList: null,
        //activeLanguageKeywordsList: null,
        currentUserDiscount: null,
        accessToken: null,
        websiteList: null,
        currentWebsite: null,
        currentWebsitePlanStatus: {
          isExpired: false,
          isTrial: false,
          isInit: false,
          isFreeWidget: false,
          isProPlan: false,
          isCanceledPlan: false,
          planName: null,
          planExpiredData: null
        },
        currentWebsiteData: {
          accessibilityScore: null,
          violationsRemediatedPercentage: null,
          successViolations: null,
          failedViolations: null,
          imageAltRemediatedPercentage: null,
          successImageAlt: null,
          failedImageAlt: null,
          successViolationsList: null,
          failedViolationsList: null,
        },
        currentWebsitePlanList: null
      };

    case LOADING:
      return {
        ...state,
        isLoading: payload
      }

    case TRANSLATION_LOADING:
      return {
        ...state,
        translationLoading: payload
      }

    case API_RECALL:
      return {
        ...state,
        apiRecall: payload
      }

    case SET_ACTIVE_LANGUAGE:
      return {
        ...state,
        activeLanguage: payload
      }
    case SET_LANGUAGE_LIST:
      return {
        ...state,
        languageList: payload
      }
    case SET_LANGUAGE_KEYWORDS_LIST:
      return {
        ...state,
        activeLanguageKeywordsList: payload
      }
    case SET_TRANSLATIONS:
      return {
        ...state,
        translations: payload
      }
    case SET_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: payload
      }
    case SET_MESSAGE:
      return {
        ...state,
        toastMessage: payload
      }
    case CLEAR_MESSAGE:
      return {
        ...state,
        toastMessage: null
      }
    case SET_CURRENT_WEBSITE:
      return {
        ...state,
        currentWebsite: payload
      }
    case SET_CURRENT_WEBSITE_PLAN_DATA:
      return {
        ...state,
        currentWebsitePlanStatus: payload
      }
    case SET_WEBSITE_LIST:
      return {
        ...state,
        websiteList: payload
      }
    case SET_CURRENT_WEBSITE_DATA:
      return {
        ...state,
        currentWebsiteData: payload
      }
    case SET_CURRENT_WEBSITE_PLAN_LIST:
      return {
        ...state,
        currentWebsitePlanList: payload
      }

    default:
      return state;
  }
};
export default CombinedReducer
