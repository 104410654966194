import { useSelector } from "react-redux";
import { StoreProptypes } from "../../PropTypes/StoreProptypes";
import { useTranslation } from "react-i18next";
import DashboardPagesTemplate from "../../Template/DashboardPagesTemplate";
import DashboardPageTitle from "../../Components/InnerPageTitle";

import CopyWidgetScriptCode from "../../Components/ScriptCode";

const ScriptsPage = () => {

  // Redux Data
  const { currentWebsite } = useSelector((store: StoreProptypes) => store);

  // Functions
  const { t } = useTranslation();



  return (
    <>
      <DashboardPagesTemplate>

        <DashboardPageTitle
          pageTitle={`${t("Embed Code")}`}
          subPageTitle={`${t('page subtitle', { domainName: currentWebsite?.domain })}`}
        />
        {(currentWebsite && currentWebsite.current_package_only && currentWebsite.current_package_only.length > 0) ? (
          <>
            <CopyWidgetScriptCode
              widgetAPIKey={currentWebsite.current_package_only[0].api_key}
              widgetPosition={currentWebsite.widget_position !== null ? currentWebsite.widget_position : `bottom_right`}
              widgetColor={currentWebsite.widget_color_code !== null ? currentWebsite.widget_color_code : `#420083`}
            />
          </>
        ):(<>
        </>)}

      </DashboardPagesTemplate>

    </>
  )
}

export default ScriptsPage;
