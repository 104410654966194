import { LOADING, SET_CURRENT_WEBSITE, SET_MESSAGE } from "../../Actions/Types";
import { DomainDetailsApi } from "../../Api/DomainApi/DomainDetailsApi";
import { updateWidgetSettingsApi } from "../../Api/UpdateWidgetSettingsApi";

const updateWidgetSettingsService = (
    token: string,
    websiteID: number,
    dispatch: Function,
    t: Function,
    changeWidgetIconType: number,
    isChangeWidgetIconCustomSize: boolean,
    changeWidgetCustomIconSize: number,
    isShowCustomWidgetIconSizeMobile: boolean,
    changeWidgetIconSizeMobile: Number,
    isChangeWidgetIconCustomSizeMobile: boolean,
    changeWidgetIconCustomSizeMobile: Number,
    changeWidgetIconSize: number,
    changeWidgetIconColor: string,
    changeWidgetIconPosition: string,
    isChangeWidgetIconCustomPosition: boolean,
    changeWidgetIconCustomLeftPosition: number | null,
    changeWidgetIconCustomRightPosition: number | null,
    changeWidgetIconCustomTopPosition: number | null,
    changeWidgetIconCustomBottomPosition: number | null,
    changeWidgetSize: number,
    changeWidgetAccessibilityStatementLink: string,
    changeGaID: string,
    changeAdobeID: string

) => {

    // Set Loading
    dispatch({
        type: LOADING,
        payload: true,
    });

    updateWidgetSettingsApi(
        token,
        websiteID,
        changeWidgetIconType,
        isChangeWidgetIconCustomSize,
        changeWidgetCustomIconSize,
        isShowCustomWidgetIconSizeMobile,
        changeWidgetIconSizeMobile,
        isChangeWidgetIconCustomSizeMobile,
        changeWidgetIconCustomSizeMobile,
        changeWidgetIconSize,
        changeWidgetIconColor,
        changeWidgetIconPosition,
        isChangeWidgetIconCustomPosition,
        changeWidgetIconCustomLeftPosition,
        changeWidgetIconCustomRightPosition,
        changeWidgetIconCustomTopPosition,
        changeWidgetIconCustomBottomPosition,
        changeWidgetSize,
        changeWidgetAccessibilityStatementLink,
        changeGaID,
        changeAdobeID
    ).then((res) => {

        if (res.status === 200) {

            // Domain Details
            DomainDetailsApi(token, websiteID).then((domainResponse) => {
                if (res.status === 200) {

                    // Set Current Website Data
                    dispatch({
                        type: SET_CURRENT_WEBSITE,
                        payload: domainResponse.Data[0]
                    });

                    // Set Loading
                    dispatch({
                        type: LOADING,
                        payload: false,
                    });

                    // Res Msg
                    setTimeout(() => {
                        dispatch({
                            type: SET_MESSAGE,
                            payload: {
                                "toast_message": t('widget settings update success msg'),
                                "toast_type": "success",
                                "onclose": null
                            }
                        });
                    }, 2000)

                }
            })

        } else {

            // Set Loading
            dispatch({
                type: LOADING,
                payload: false,
            });

            // Res Msg
            dispatch({
                type: SET_MESSAGE,
                payload: {
                    "toast_message": t('widget settings update fail msg'),
                    "toast_type": "danger",
                    "onclose": null
                }
            });

        }

    })
}

export default updateWidgetSettingsService;