import aioaLogo from "../../Assets/img/all-in-one-accessibility-logo.svg";

const DashboardLogo = () => {
    return (
        <>
            <div className="aioa_dashboard-logo">
                <img src={aioaLogo} alt="All in One Accessibility Logo" />
            </div>
        </>
    )
}

export default DashboardLogo;