import { Form } from "react-bootstrap";
import { DraggableProvidedDragHandleProps, DraggableProvidedDraggableProps, DraggableStateSnapshot } from "react-beautiful-dnd";
import React, { forwardRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { StoreProptypes } from "../../../PropTypes/StoreProptypes";
import { MenulistPropTypes } from "../../../PropTypes/EnableDisableServicesPropTypes";
const baseURL = `${process.env.REACT_APP_BASE_URL}`;

interface PropTypes {
    item: MenulistPropTypes,
    childrenRef: any,
    dragHandleProps: DraggableProvidedDragHandleProps | null | undefined,
    otherProp: DraggableProvidedDraggableProps
    snapshot: DraggableStateSnapshot,
    handleLanguageStatus: Function
}

const LanguagesListChildrenElement = forwardRef((prop: PropTypes) => {

    const { item, childrenRef, otherProp, snapshot, dragHandleProps, handleLanguageStatus } = prop;

    const { languageList } = useSelector((store: StoreProptypes) => store);

    const [langFlag, setLangFlag] = useState('');

    useEffect(()=>{
        if (item && languageList && languageList.length > 0) {
            const selectItemFlag = languageList.filter((selectLang) => selectLang.code === item.slug).map((selectItem) => {
                return selectItem.icon;
            })
            setLangFlag(selectItemFlag[0]);
        }
    },[item])

    return (
        <div className={`menu-box border ${snapshot.isDragging ? `hovering` : ``}`} ref={childrenRef} {...otherProp}>
            <div className="drag-icon" style={{ cursor: 'all-scroll' }} {...dragHandleProps}>
                <i className="material-symbols-outlined" aria-hidden="true">drag_indicator</i>
            </div>
            <div className="menu-name icon-with-name ms-2 me-2">
                <div className="icon border"><img src={`${baseURL}/storage/app/images/languageicon/${langFlag}`} alt="" /></div>
                <div className="name h6 mb-0 ms-2">{item.name}</div>
            </div>
            <div className="action-part">
                <Form.Check
                    className="form-check m-0 p-0 d-flex align-items-center"
                    type="checkbox"
                    id={`accessibility-menu-${item.id}`}
                    area-label={`accessibility`}
                    onChange={(e) => handleLanguageStatus(e, item.id)}
                    defaultChecked={item.status === 1 ? true : false}
                />
            </div>
        </div>
    )
});

export default LanguagesListChildrenElement;