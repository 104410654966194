import { Button } from "react-bootstrap";
import SupportButtonIcon from "../../Assets/img/icon-customer-service.svg";


import "../../Assets/scss/components/support-button.scss";
import { useTranslation } from "react-i18next";

const DashboardSupportButton = () => {

     // Functions
     const { t } = useTranslation();

    const handleSupportLink = () => {
        window.open('https://www.skynettechnologies.com/report-accessibility-problem', '_blank')
    }

    return (
        <>
            <Button className="aioa_dashboard-support-btn" variant="outline-primary" tabIndex={0} onClick={() => handleSupportLink()}>
                <div className="aioa_dashboard-support-icon">
                    <img src={SupportButtonIcon} alt="" aria-hidden="true" />
                </div>
                <div className="aioa_dashboard-support-name">{t('Support')}</div>
            </Button>
        </>
    )
}

export default DashboardSupportButton;