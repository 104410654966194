import "../../Assets/scss/pages/widget-manage-plan.scss";

import { useTranslation } from "react-i18next";
import DashboardPageTitle from "../../Components/InnerPageTitle";
import DashboardPagesTemplate from "../../Template/DashboardPagesTemplate";
import { useSelector } from "react-redux";
import { StoreProptypes } from "../../PropTypes/StoreProptypes";
import { Accordion } from "react-bootstrap";
import AllUserOnlyActivePlanInfo from "../../Components/ManagePlan/CurrentActivePlanInfo";
import AllUserOnlyExpiredPlanInfo from "../../Components/ManagePlan/ExpiredPlanPlanInfo";
import AllUserAllPlanInfo from "../../Components/ManagePlan/AllPlanInfo";
import FreeWidgetPlanInfo from "../../Components/ManagePlan/FreeWidgetPlanInfo";
import AllUserOnlyTrialPlanInfo from "../../Components/ManagePlan/CurrentTrialPlanInfo";
import PlatformWidgetCurrentActivePlanInfo from "../../Components/ManagePlan/PlatformWidgetPlanInfo";
import AllUserOnlyCanceledPlanInfo from "../../Components/ManagePlan/CanceledPlanInfo";

const ManagePlanPage = () => {

  // Redux Data
  const { accessToken, currentWebsite, activeLanguage, user, currentWebsitePlanStatus } = useSelector((store: StoreProptypes) => store);

  // Functions
  const { t } = useTranslation();

  return (
    <>
      <DashboardPagesTemplate>
        <DashboardPageTitle
          pageTitle={`${t("Manage Plan")}`}
          subPageTitle={`For ${currentWebsite?.domain}`}
        />

        <Accordion className="aioa_dashboard-manage-plan" defaultActiveKey={['0', '1', '2', '3', '4', '5', '6']} alwaysOpen>
          <Accordion.Item eventKey="0">
            <Accordion.Header className="h4">{t("Widget Plans")}</Accordion.Header>
            <Accordion.Body>

              {currentWebsite && ((currentWebsite.platform === "wix" || currentWebsite.platform === 'shopify' || currentWebsite.platform === "Opencart-Pro" || currentWebsite.platform === "Magento-Pro")) ? (
                <>
                  <PlatformWidgetCurrentActivePlanInfo />
                </>
              ) : (
                <>
                  {currentWebsite && currentWebsitePlanStatus && (
                    <>
                      {currentWebsitePlanStatus.planExpiredData === null && currentWebsitePlanStatus.planName === null ? (
                        <>
                          <AllUserAllPlanInfo />
                        </>
                      ) : (
                        <>
                          {currentWebsitePlanStatus.isExpired ? (
                            <>
                              {currentWebsite.expired_package_detail && currentWebsite.expired_package_detail.length > 0 && (
                                <>
                                  <AllUserOnlyExpiredPlanInfo />
                                </>
                              )}
                            </>
                          ) : currentWebsitePlanStatus.isCanceledPlan ? (
                            <>
                              <AllUserOnlyCanceledPlanInfo />
                            </>
                          ) : (
                            <>
                              {currentWebsite.current_package_only && currentWebsite.current_package_only.length > 0 && (
                                <>
                                  {currentWebsitePlanStatus.isTrial ? (
                                    <>
                                      <AllUserOnlyTrialPlanInfo />
                                    </>
                                  ) : currentWebsitePlanStatus.isFreeWidget ? (
                                    <>
                                      <FreeWidgetPlanInfo />
                                    </>
                                  ) : (
                                    <>
                                      <>
                                        <AllUserOnlyActivePlanInfo />
                                      </>
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}

            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

      </DashboardPagesTemplate>
    </>
  )
}

export default ManagePlanPage;