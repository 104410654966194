import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { StoreProptypes } from "../../PropTypes/StoreProptypes";
import { getLanguagesList, setLanguage } from "../../Services/LanguageService";
import i18n from "i18next";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import "../../Assets/scss/components/language-button.scss";
import { LOADING, TRANSLATION_LOADING } from "../../Actions/Types";
const baseURL = `${process.env.REACT_APP_BASE_URL}`;

const DashboardLanguageButton = () => {

    const { languageList, activeLanguage, activeLanguageKeywordsList } = useSelector((store: StoreProptypes) => store);

    // Functions
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();

    // States
    const [showLanguageModal, setShowLanguageModal] = useState<boolean>(false);

    const handleLanguageModalClose = () => {
        setShowLanguageModal(false);
    }
    const handleLanguageModalShow = () => {
        setShowLanguageModal(true);
    }

    // Get Language List
    useEffect(() => {
        if (!languageList) {
            getLanguagesList(dispatch);
        }
    }, []);

    useEffect(() => {
        if (!activeLanguageKeywordsList) {
            getLanguagesList(dispatch);
        }
    }, []);

    i18n.on("languageChanged", () => {
        console.log("language change detected", i18n.language);
        dispatch({
            type: TRANSLATION_LOADING,
            payload: false
        });
    })

    useEffect(() => {

        // Set HTML Tag Lang Attribute
        if (activeLanguage) {
            const html = document.getElementsByTagName("html")[0];
            const body = document.getElementsByTagName("body")[0];

            html.setAttribute("lang", activeLanguage.code);

            if (activeLanguage.is_rtl) {
                html.setAttribute("dir", "rtl");
                body.classList.add("rtl");
            }
            else {
                html.setAttribute("dir", "ltr");
                body.classList.remove("rtl");
            }

        }

        // Translation
        if (activeLanguage && activeLanguageKeywordsList) {

            if (!(i18n.hasResourceBundle(activeLanguage.code, 'translation'))) {
                i18n.addResources(
                    activeLanguage.code,
                    'translation',
                    { ...activeLanguageKeywordsList },

                );
            }
            setTimeout(() => {
                i18n.changeLanguage(activeLanguage.code);
            }, 1000)

        }
    }, [activeLanguage, activeLanguageKeywordsList])


    return (
        <>
            {activeLanguage && (
                <>
                    <Button className="aioa_dashboard-lang-btn" variant="outline-primary" tabIndex={0} onClick={() => handleLanguageModalShow()}>
                        <div className="aioa_dashboard-lang-flag">
                            {activeLanguage.code.length > 2 ? (
                                <>
                                    {activeLanguage.code.slice(-2)}
                                </>
                            ) : (
                                <>
                                    {activeLanguage.code}
                                </>
                            )}
                        </div>
                        <div className="aioa_dashboard-lang-name">{activeLanguage.original_name}</div>
                    </Button>
                </>
            )}

            <Modal show={showLanguageModal} onHide={handleLanguageModalClose} size="lg" fullscreen="lg-down" backdrop="static" centered>
                <Modal.Header closeButton>
                    <Modal.Title>{t('Language Popup Title')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='aioa_dashboard-language-list'>
                        <Row xl={2} className="g-4">
                            {languageList?.map(lang => {
                                let activeClass = activeLanguage?.id === lang.id ? 'active' : '';
                                return (
                                    <Col key={lang.id}>
                                        <Button
                                            key={lang.id}
                                            onClick={() => { setLanguage(lang, dispatch); handleLanguageModalClose() }}
                                            data-lang={lang.name}
                                            className={`${activeClass} aioa_dashboard-lang-btn`}
                                            aria-pressed={activeClass ? 'true' : 'false'}
                                        >
                                            <div className="aioa_dashboard-lang-flag">
                                                {lang.code.length > 2 ? (
                                                    <>
                                                        {lang.code.slice(-2)}
                                                    </>
                                                ) : (
                                                    <>
                                                        {lang.code}
                                                    </>
                                                )}
                                            </div>
                                            <div className='aioa_dashboard-lang-name'>{lang.original_name}</div>
                                        </Button>
                                    </Col>
                                )
                            })}
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default DashboardLanguageButton;