import { LOADING, SET_MESSAGE } from "../../Actions/Types";
import { saveNormalWebsiteApi } from "../../Api/DomainApi/SaveNormalDomainApi"
import encodeBase64 from "../../Helpers/encodeBase64";

const saveNormalDomainService = (
    token: string,
    newWebsiteURL: string,
    userID: number,
    newWebsitePlanID: number,
    newWebsitePlanType: string,
    activeLanguageCode: string,
    dispatch: Function,
    t: Function,
    navigate: Function
) => {

    // Set Loading
    dispatch({
        type: LOADING,
        payload: true,
    });

    saveNormalWebsiteApi(token, newWebsiteURL, userID, newWebsitePlanID).then((res) => {
        if (res.Data) {

            const urlString = `${newWebsitePlanID}|${newWebsiteURL.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('/')[0]}|${newWebsitePlanType}|${userID}|${activeLanguageCode}|1`;
            const encodedString = encodeBase64(urlString);
            navigate(`/front/package/${encodedString}`);

            // Set Loading
            dispatch({
                type: LOADING,
                payload: false,
            });

        } else {
            //console.info(res.error);
            dispatch({
                type: SET_MESSAGE,
                payload: {
                    "toast_message": res.error,
                    "toast_type": "danger"
                }
            });

            // Set Loading
            dispatch({
                type: LOADING,
                payload: false,
            });
        }
    })
}

export { saveNormalDomainService }