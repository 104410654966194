import { Button, Card, Col, Form, InputGroup, Placeholder, Row } from "react-bootstrap";
import PaginationComponent from "../../Components/Pagination";
import ShowRecordItem from "../../Components/ShowRecordItem";
import NoRecordFound from "../../Components/NoRecordFound";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { StoreProptypes } from "../../PropTypes/StoreProptypes";
import { useSelector } from "react-redux";
import { useEffect, useMemo, useRef, useState } from "react";
import { WhiteLabelWebsitePropTypes } from "../../PropTypes/WebsitePropTypes";
import LogoPreview from "../../Assets/img/logo-placeholder-image.webp";
import { debounce } from "lodash";
import { UpdateWhiteLabelServiceDomainStatus, getWhiteLabelServiceDomainList, updateBrandDetailsService } from "../../Services/WhitelabelActionService";

import "../../Assets/scss/components/custom-branding-service.scss";

const CustomBrandingService = () => {

    // Functions
    const { t } = useTranslation();
    const dispatch = useDispatch();

    // Redux
    const { accessToken, currentWebsite, activeLanguage, user } = useSelector((store: StoreProptypes) => store);

    // State
    const [loading, setLoading] = useState(false);
    const [loadingLogo, setLoadingLogo] = useState(false);
    const [loadingURL, setLoadingURL] = useState(false);
    const [customBrndOption, setCustomBrndOption] = useState(false);
    const [customBrndLogo, setCustomBrndLogo] = useState<File>();
    const [customBrndLogoValidation, setCustomBrndLogoValidation] = useState<boolean>(true);
    const [customBrndLogoURL, setCustomBrndLogoURL] = useState<string | null>();
    const [customBrndLogoSize, setCustomBrndLogoSize] = useState<number>(0);
    const [customBrndLogoURLPreviewToggle, setCustomBrndLogoURLPreviewToggle] = useState(false);
    const [customBrndLogoURLPreview, setCustomBrndLogoURLPreview] = useState<string | null>();
    const [customBrndLogoError, setCustomBrndLogoError] = useState(false);
    const [customBrndLogoName, setCustomBrndLogoName] = useState('');
    const [customBrndURL, setCustomBrndURL] = useState('');

    const [customBrndLogoFiledValidation, setCustomBrndLogoFiledValidation] = useState<boolean>(false);
    const [customBrndLogoValidationMsg, setCustomBrndLogoValidationMsg] = useState('');
    const [customBrndURLValidation, setCustomBrndURLValidation] = useState<boolean>(false);
    const [customBrndURLValidationMsg, setCustomBrndURLValidationMsg] = useState('');

    const [apiRecall, setApiRecall] = useState<boolean>(false);

    const [activeWebsiteList, setActiveWebsiteList] = useState<WhiteLabelWebsitePropTypes[]>([]);
    const [activeWebsiteApiLoader, setActiveWebsiteApiLoader] = useState<boolean>(false)
    const [activeDomainTotalRecord, setActiveDomainTotalRecord] = useState<number>(0);
    const [activeDomainMainTotalRecord, setActiveDomainMainTotalRecord] = useState<number>(0);
    const [activeDomainCurrentPage, setActiveDomainCurrentPage] = useState<number>(1);
    const [activeDomainLimit, setActiveDomainLimit] = useState<number>(10);
    const [activeDomainOffset, setActiveDomainOffset] = useState<number>(0);
    const [activeDomainTerms, setActiveDomainTerms] = useState<string>("");
    const [domainFilter, setDomainFilter] = useState<number>(4);


    const changeHandler = (e: any) => {
        const target = e.target;
        const fileUploaded = target.files;

        if (e.target.files[0] !== undefined) {
            if ((e.target.files[0].type === 'image/svg+xml' || e.target.files[0].type === 'image/png')) {
                setCustomBrndLogoValidation(false);
                setCustomBrndLogoError(false);
                setCustomBrndLogoSize(e.target.files[0].size);
                const fileSize = parseFloat((((e.target.files[0].size) / 1024) / 1024).toFixed(2));

                if ((fileSize > 2)) {
                    setCustomBrndLogoFiledValidation(true);
                    setLoadingLogo(true);
                    setCustomBrndLogoValidationMsg('The maximum allowed file size is 2MB.')
                } else {
                    setLoadingLogo(false);
                    setCustomBrndLogoFiledValidation(false)
                    setCustomBrndLogo(e.target.files[0]);
                    setCustomBrndLogoName(e.target.files[0].name)
                    if (fileUploaded) {
                        let reader = new FileReader();
                        reader.readAsDataURL(fileUploaded[0]);

                        reader.onload = (e) => {
                            if (reader && typeof reader.result === "string") {
                                setCustomBrndLogoURLPreview(reader.result);
                                setCustomBrndLogoURLPreviewToggle(true);
                            }
                        }
                    }
                }
            } else {
                setCustomBrndLogoError(true);
                setCustomBrndLogoFiledValidation(true);
                setLoadingLogo(true);
                setCustomBrndLogoValidationMsg('Please upload png or svg image.')
            }
        }

    };

    useEffect(() => {
        if (user) {
            if (user.department === 4) {
                if (user.brand_logo !== '' && user.brand_logo !== null && user.brand_logo !== undefined) {
                    setCustomBrndLogoURL(user.brand_logo);
                    setCustomBrndLogoValidation(false);
                } else {
                    setCustomBrndLogoURL(null);
                    setCustomBrndLogoValidation(true);
                }
                if (user.website !== null) {
                    setCustomBrndURL(user.website);
                } else {
                    setCustomBrndURL('');
                }
            } else {

            }
        }
    }, [user]);

    // 
    const prevLimitRef = useRef<number>(10);
    useEffect(() => {
        setActiveDomainCurrentPage(1);
    }, [activeDomainLimit, activeDomainTerms, domainFilter]);
    useEffect(() => {
        setActiveDomainOffset(0);
    }, [activeDomainTerms, domainFilter]);
    useEffect(() => {
        setActiveDomainOffset(activeDomainCurrentPage * activeDomainLimit - activeDomainLimit);
    }, [activeDomainCurrentPage, activeDomainLimit]);
    const searchActiveDomainChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setActiveDomainTerms(e.target.value);
    };
    const debouncedSearchActiveDomainHandler = useMemo(() => {
        return debounce(searchActiveDomainChangeHandler, 500);
    }, []);
    useEffect(() => {
        if (accessToken && ((activeDomainLimit === prevLimitRef.current))) {
            getWhiteLabelServiceDomainList(accessToken, 6, 1, activeDomainLimit, activeDomainOffset, activeDomainTerms, setActiveDomainTotalRecord, setActiveDomainMainTotalRecord, setActiveWebsiteList, setActiveWebsiteApiLoader);
        }
    }, [activeDomainOffset, activeDomainTerms, accessToken]);

    useEffect(() => {
        if ((activeDomainLimit !== prevLimitRef.current) && activeDomainOffset === 0) {
            if (accessToken) {
                getWhiteLabelServiceDomainList(accessToken, 6, 1, activeDomainLimit, activeDomainOffset, activeDomainTerms, setActiveDomainTotalRecord, setActiveDomainMainTotalRecord, setActiveWebsiteList, setActiveWebsiteApiLoader);
            }
            prevLimitRef.current = activeDomainLimit;
        }
    }, [activeDomainOffset, activeDomainLimit, domainFilter]);


    // whitelabel Service For Inactive Domain 
    const [inactiveWebsiteList, setInactiveWebsiteList] = useState<WhiteLabelWebsitePropTypes[]>([]);
    const [inactiveWebsiteApiLoader, setInactiveWebsiteApiLoader] = useState<boolean>(false)
    const [inactiveDomainTotalRecord, setInactiveDomainTotalRecord] = useState<number>(0);
    const [inactiveDomainMainTotalRecord, setInactiveDomainMainTotalRecord] = useState<number>(0);
    const [inactiveDomainCurrentPage, setInactiveDomainCurrentPage] = useState<number>(1);
    const [inactiveDomainLimit, setInactiveDomainLimit] = useState<number>(10);
    const [inactiveDomainOffset, setInactiveDomainOffset] = useState<number>(0);
    const [inactiveDomainTerms, setInactiveDomainTerms] = useState<string>("");
    const prevInactiveLimitRef = useRef<number>(10);
    useEffect(() => {
        setInactiveDomainCurrentPage(1);
    }, [inactiveDomainLimit, inactiveDomainTerms]);
    useEffect(() => {
        setInactiveDomainOffset(0);
    }, [activeDomainTerms]);
    useEffect(() => {
        setInactiveDomainOffset(inactiveDomainCurrentPage * inactiveDomainLimit - inactiveDomainLimit);
    }, [inactiveDomainCurrentPage, inactiveDomainLimit]);
    const searchInActiveDomainChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInactiveDomainTerms(e.target.value);
    };
    const debouncedSearchInActiveDomainHandler = useMemo(() => {
        return debounce(searchInActiveDomainChangeHandler, 500);
    }, []);
    useEffect(() => {
        if (accessToken && ((inactiveDomainLimit === prevInactiveLimitRef.current))) {
            getWhiteLabelServiceDomainList(accessToken, 6, 0, inactiveDomainLimit, inactiveDomainOffset, inactiveDomainTerms, setInactiveDomainTotalRecord, setInactiveDomainMainTotalRecord, setInactiveWebsiteList, setInactiveWebsiteApiLoader);
        }
    }, [inactiveDomainOffset, inactiveDomainTerms, accessToken]);

    useEffect(() => {
        if ((inactiveDomainLimit !== prevInactiveLimitRef.current) && inactiveDomainOffset === 0) {
            if (accessToken) {
                getWhiteLabelServiceDomainList(accessToken, 6, 0, inactiveDomainLimit, inactiveDomainOffset, inactiveDomainTerms, setInactiveDomainTotalRecord, setInactiveDomainMainTotalRecord, setInactiveWebsiteList, setInactiveWebsiteApiLoader);
            }
            prevInactiveLimitRef.current = inactiveDomainLimit;
        }
    }, [inactiveDomainOffset, inactiveDomainLimit, domainFilter]);


    useEffect(() => {
        if (apiRecall && accessToken) {
            getWhiteLabelServiceDomainList(accessToken, 6, 1, activeDomainLimit, activeDomainOffset, activeDomainTerms, setActiveDomainTotalRecord, setActiveDomainMainTotalRecord, setActiveWebsiteList, setActiveWebsiteApiLoader);
            getWhiteLabelServiceDomainList(accessToken, 6, 0, inactiveDomainLimit, inactiveDomainOffset, inactiveDomainTerms, setInactiveDomainTotalRecord, setInactiveDomainMainTotalRecord, setInactiveWebsiteList, setInactiveWebsiteApiLoader);
        }
    }, [apiRecall])

    // Array Murge
    const [finalArray, setFinalArray] = useState<WhiteLabelWebsitePropTypes[]>([])
    const [selectedWebsiteList, setSelectedWebsiteList] = useState<WhiteLabelWebsitePropTypes[]>([]);
    const [saveButtonLoading, setSaveButtonLoading] = useState<boolean>(false);

    useEffect(() => {
        if (activeWebsiteList || inactiveWebsiteList) {
            setFinalArray([...activeWebsiteList, ...inactiveWebsiteList]);
            setApiRecall(false);
        }
    }, [activeWebsiteList, inactiveWebsiteList])


    const checkDifference = (
        A: WhiteLabelWebsitePropTypes[],
        B: WhiteLabelWebsitePropTypes[]
    ) => {
        const tempArray = selectedWebsiteList.filter((BItem) => {
            return !A.some((AItem) => BItem.id === AItem.id);
        });
        return tempArray;
    };

    const mergeArray = (
        A: WhiteLabelWebsitePropTypes[],
        B: WhiteLabelWebsitePropTypes[]
    ) => {
        let mergedArray = [...A, ...B];
        return mergedArray;
    };

    const popArray = (
        A: WhiteLabelWebsitePropTypes[],
        B: WhiteLabelWebsitePropTypes[]
    ) => {
        const tempArray = A.filter((item) => {
            return !B.some((Bitem) => item.id === Bitem.id);
        });
        return tempArray;
    };


    const HandleWhiteLabelStatus = (item: WhiteLabelWebsitePropTypes, flag: Boolean, status: number) => {
        if (status === 1) {
            if (flag) {
                setSelectedWebsiteList(mergeArray(selectedWebsiteList, [{ ...item, adon_status: 0 }]))
            } else {
                setSelectedWebsiteList(popArray(selectedWebsiteList, [item]))
            }
        } else {
            if (flag) {
                setSelectedWebsiteList(mergeArray(selectedWebsiteList, [{ ...item, adon_status: 1 }]))
            } else {
                setSelectedWebsiteList(popArray(selectedWebsiteList, [item]))
            }
        }
    }


    //

    const HandleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        var httpRegex = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/;
        if (customBrndLogoValidation) {
            setCustomBrndLogoFiledValidation(true);
            return setCustomBrndLogoValidationMsg('Please upload logo.');
        } else if (customBrndURL === '') {
            setCustomBrndURLValidation(true);
            return setCustomBrndURLValidationMsg("Please enter logo URL.");
        } else if (!httpRegex.test(customBrndURL)) {
            setCustomBrndURLValidation(true);
            return setCustomBrndURLValidationMsg("Please enter valid URL.");
        } else {
            if (accessToken && currentWebsite && user) {
                updateBrandDetailsService(accessToken, user.id, customBrndURL, setLoading, dispatch, t, customBrndLogo);
                setCustomBrndLogoName('');
            }
        }
    }

    const HandleWhiteLabelStatusUpdate = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        if (customBrndURL || customBrndLogo) {
            HandleSubmit(e);
        }

        if (accessToken && selectedWebsiteList && selectedWebsiteList.length > 0) {
            UpdateWhiteLabelServiceDomainStatus(accessToken, 6, selectedWebsiteList, setApiRecall, dispatch, t, setSelectedWebsiteList);
        }
    }

    const URLValidationEvent = (e: any) => {
        const inputValue = e.target.value;
        let httpRegex = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/;

        //console.log("inputValue ==>", inputValue);

        if (inputValue === '') {
            setCustomBrndURLValidation(true);
            setLoadingURL(true);
            setCustomBrndURLValidationMsg("Please enter logo URL.");
        } else if (!httpRegex.test(inputValue)) {
            setCustomBrndURLValidation(true);
            setLoadingURL(true);
            setCustomBrndURLValidationMsg("Please enter valid URL like https://skynettechnologies.com or http://skynettechnologies.com.");
        } else {
            setCustomBrndURLValidation(false);
            setLoadingURL(false);
        }

        setCustomBrndURL(inputValue);
    }

    return (
        <>
            <div className="aioa_dashboard-custom-branding-service-wrapper">

                <div className="aioa_dashboard-custom-branding-service-logo-upload-wrapper">
                    <Form>
                        <Row xl={2}>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <div className="logo-preview-main">
                                            <div className="logo-preview">
                                                {customBrndLogoURLPreviewToggle ? (
                                                    <>
                                                        <img src={customBrndLogoURLPreview ? customBrndLogoURLPreview : LogoPreview} />
                                                    </>
                                                ) : (
                                                    <>

                                                        <img src={customBrndLogoURL ? `${process.env.REACT_APP_BASE_URL}/storage/app/brand_logos/${customBrndLogoURL}` : LogoPreview} alt={t("Logo preview").toString()} />
                                                    </>
                                                )}
                                            </div>
                                            <div className="logo-upload">
                                                <div className="logo-upload-inner">
                                                    <div className="logo-upload-input">
                                                        <Form.Label htmlFor="upload_file" className="btn btn-primary">{t("Upload Logo")}</Form.Label>
                                                        <InputGroup className="input-group-outline ">
                                                            <Form.Control id="upload_file" type="file" aria-describedby="file_input_description" onChange={(e) => changeHandler(e)} className="ps-0" />
                                                        </InputGroup>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="file-url mt-2">{customBrndLogoName}</div>

                                        <div id="file_input_description">
                                            {customBrndLogoFiledValidation && (
                                                <>
                                                    <div className="mt-3">
                                                        <strong className="text-danger">{customBrndLogoValidationMsg}</strong>
                                                    </div>
                                                </>
                                            )}

                                            <div className="mt-3">
                                                <strong>{t("Accepted File Formats")}</strong>: png, svg. <br />
                                                <strong>{t("Recommended image dimensions")}</strong>: 300px X 125px
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <div className="logo-upload">
                                            <Form.Label htmlFor="logo_url"><strong>{t("Logo URL")}</strong></Form.Label>
                                            <Form.Control aria-describedby="logo_url_description" size="lg" id="logo_url" type="text" defaultValue={customBrndURL} onChange={(e) => URLValidationEvent(e)} onBlur={(e) => URLValidationEvent(e)} />
                                            <div id="logo_url_description">
                                                {customBrndURLValidation && (
                                                    <>
                                                        <div className="mt-2">
                                                            <strong className="text-danger">{customBrndURLValidationMsg}</strong>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Form>
                </div>

                <div className="aioa_dashboard-custom-branding-service-domain-list-wrapper">
                    <Row xl={2}>
                        <Col>
                            <Card>
                                <Card.Header>
                                    <Card.Title className="mb-0">{t("Select domain to remove custom branding service")}</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <div className="aioa_dashboard-custom-branding-service-domain-list-table">
                                        <div className={`aioa_dashboard-order-list-filter-wrapper`} id="aioa_dashboard-order-list-filter">

                                            <div className="aioa_dashboard-order-list-filter-block search-block">
                                                <Form.Label className='d-lg-none'>{t("Search")}</Form.Label>
                                                <InputGroup>
                                                    <Form.Control
                                                        placeholder={t("Search Domain").toString()}
                                                        aria-label={t("Search Domain").toString()}
                                                        type="text"
                                                        onChange={debouncedSearchActiveDomainHandler}
                                                        disabled={activeDomainMainTotalRecord > 0 ? false : true}
                                                    />
                                                    <InputGroup.Text><i className="material-symbols-outlined" aria-hidden="true">search</i></InputGroup.Text>
                                                </InputGroup>
                                            </div>

                                            <div className="aioa_dashboard-order-list-filter-block">
                                                <Form.Label htmlFor='showPages'>{t("Items Per Page")}</Form.Label>
                                                <Form.Select
                                                    onChange={(e) => setActiveDomainLimit(parseInt(e.target.value))}
                                                    id="items_per_page"
                                                    defaultValue={activeDomainLimit}
                                                    disabled={activeDomainMainTotalRecord > 0 ? false : true}
                                                >
                                                    <option value="10" selected={(activeDomainLimit === 10) ? true : false}>{t("10")}</option>
                                                    <option value="20" selected={(activeDomainLimit === 20) ? true : false}>{t("20")}</option>
                                                    <option value="40" selected={(activeDomainLimit === 40) ? true : false}>{t("40")}</option>
                                                    <option value="80" selected={(activeDomainLimit === 80) ? true : false}>{t("80")}</option>
                                                    <option value="100" selected={(activeDomainLimit === 100) ? true : false}>{t("100")}</option>
                                                </Form.Select>
                                            </div>

                                        </div>

                                        <div className="aioa_dashboard-table">
                                            <div className="aioa_dashboard-table-thead">
                                                <div className="aioa_dashboard-table-tr">
                                                    <div className="aioa_dashboard-table-th tcw-50 aioa_dashboard-table-cell-sidegap">&nbsp;</div>
                                                    <div className="aioa_dashboard-table-th tcw-auto aioa_dashboard-table-cell-sidegap">{t("Domain")}</div>
                                                </div>
                                            </div>
                                            <div className="aioa_dashboard-table-body" aria-live='polite'>
                                                {!(activeWebsiteApiLoader) ? (
                                                    <>
                                                        {activeWebsiteList && activeWebsiteList.length > 0 ? (
                                                            <>
                                                                {activeWebsiteList.map((activeItem, index) => {
                                                                    return (
                                                                        <div className={`aioa_dashboard-table-tr`} key={index + 1 + activeDomainOffset}>

                                                                            <div className="aioa_dashboard-table-td tcw-50 aioa_dashboard-table-cell-sidegap">
                                                                                <div className='aioa_dashboard-table-td-cell-title d-none' aria-hidden="true">{t("Select Website")}</div>
                                                                                <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Select Website").toString()}>
                                                                                    <Form.Check
                                                                                        className="p-0 m-0 form-check"
                                                                                        type="checkbox"
                                                                                        aria-label={t("Select domain").toString()}
                                                                                        name={`${activeItem.id}`}
                                                                                        id={`${activeItem.id}`}
                                                                                        onChange={(e) => HandleWhiteLabelStatus(activeItem, e.target.checked, 1)}
                                                                                        defaultChecked={selectedWebsiteList.filter((selectedItem) => selectedItem.id === activeItem.id).length > 0 ? true : false}
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                            <div className="aioa_dashboard-table-td tcw-auto aioa_dashboard-table-cell-sidegap">
                                                                                <div className='aioa_dashboard-table-td-cell-title d-none' aria-hidden="true">{t("Domain")}</div>
                                                                                <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Domain").toString()}>
                                                                                    <div className="domain-name" style={{ fontWeight: 500 }}>
                                                                                        <span>{activeItem.url}</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </>
                                                        ) : (
                                                            <>
                                                                <div className='p-4'>
                                                                    <NoRecordFound imageType={`domain`} joyful={false} noRecordText={`${t('Domain not found')}`} />
                                                                </div>
                                                            </>
                                                        )}
                                                    </>
                                                ) : (
                                                    <>

                                                    </>
                                                )}
                                            </div>
                                            {activeWebsiteList && activeWebsiteList.length > 0 && (
                                                <div className="aioa_dashboard-table-pagination-main">
                                                    <>
                                                        <ShowRecordItem
                                                            offset={activeDomainOffset}
                                                            limit={activeDomainLimit}
                                                            totalRecord={activeDomainTotalRecord}
                                                        />
                                                        <PaginationComponent
                                                            currentPage={activeDomainCurrentPage}
                                                            totalRecords={activeDomainTotalRecord}
                                                            itemsPerPage={activeDomainLimit}
                                                            setCurrentPage={setActiveDomainCurrentPage}
                                                        />
                                                    </>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <Card.Header>
                                    <Card.Title className="mb-0">{t("Select domain to add custom branding service")}</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <div className="aioa_dashboard-custom-branding-service-domain-list-table">

                                        <div className={`aioa_dashboard-order-list-filter-wrapper`} id="aioa_dashboard-order-list-filter">

                                            <div className="aioa_dashboard-order-list-filter-block search-block">
                                                <Form.Label className='d-lg-none'>{t("Search")}</Form.Label>
                                                <InputGroup className="input-group-outline search-form-nobtn">
                                                    <Form.Control
                                                        placeholder={t("Search Domain").toString()}
                                                        aria-label={t("Search Domain").toString()}
                                                        type="text"
                                                        onChange={debouncedSearchInActiveDomainHandler}
                                                        disabled={inactiveDomainMainTotalRecord > 0 ? false : true}
                                                    />
                                                    <InputGroup.Text><i className="material-symbols-outlined" aria-hidden="true">search</i></InputGroup.Text>
                                                </InputGroup>
                                            </div>

                                            <div className="aioa_dashboard-order-list-filter-block">
                                                <Form.Label htmlFor='showPages'>{t("Items Per Page")}</Form.Label>
                                                <Form.Select
                                                    onChange={(e) => setInactiveDomainLimit(parseInt(e.target.value))}
                                                    id="items_per_page_1"
                                                    defaultValue={inactiveDomainLimit}
                                                    disabled={inactiveDomainMainTotalRecord > 0 ? false : true}
                                                >
                                                    <option value="10" selected={(inactiveDomainLimit === 10) ? true : false}>{t("10")}</option>
                                                    <option value="20" selected={(inactiveDomainLimit === 20) ? true : false}>{t("20")}</option>
                                                    <option value="40" selected={(inactiveDomainLimit === 40) ? true : false}>{t("40")}</option>
                                                    <option value="80" selected={(inactiveDomainLimit === 80) ? true : false}>{t("80")}</option>
                                                    <option value="100" selected={(inactiveDomainLimit === 100) ? true : false}>{t("100")}</option>
                                                </Form.Select>
                                            </div>

                                        </div>
                                        <div className="aioa_dashboard-table">
                                            <div className="aioa_dashboard-table-thead">
                                                <div className="aioa_dashboard-table-tr">
                                                    <div className="aioa_dashboard-table-th tcw-50 aioa_dashboard-table-cell-sidegap">&nbsp;</div>
                                                    <div className="aioa_dashboard-table-th tcw-auto aioa_dashboard-table-cell-sidegap">{t("Domain")}</div>
                                                </div>
                                            </div>
                                            <div className="aioa_dashboard-table-body" aria-live='polite'>
                                                {!(inactiveWebsiteApiLoader) ? (
                                                    <>
                                                        {inactiveWebsiteList && inactiveWebsiteList.length > 0 ? (
                                                            <>
                                                                {inactiveWebsiteList.map((activeItem, index) => {
                                                                    return (
                                                                        <div className={`aioa_dashboard-table-tr`} key={index + 1 + activeDomainOffset}>

                                                                            <div className="aioa_dashboard-table-td tcw-50 aioa_dashboard-table-cell-sidegap">
                                                                                <div className='aioa_dashboard-table-td-cell-title d-none' aria-hidden="true">{t("Select Website")}</div>
                                                                                <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Select Website").toString()}>
                                                                                    <Form.Check
                                                                                        className="p-0 m-0 form-check"
                                                                                        type="checkbox"
                                                                                        aria-label={t("Select domain").toString()}
                                                                                        name={`${activeItem.id}`}
                                                                                        id={`${activeItem.id}`}
                                                                                        onChange={(e) => HandleWhiteLabelStatus(activeItem, e.target.checked, 0)}
                                                                                        defaultChecked={selectedWebsiteList.filter((selectedItem) => selectedItem.id === activeItem.id).length > 0 ? true : false}
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                            <div className="aioa_dashboard-table-td tcw-auto aioa_dashboard-table-cell-sidegap">
                                                                                <div className='aioa_dashboard-table-td-cell-title d-none' aria-hidden="true">{t("Domain")}</div>
                                                                                <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Domain").toString()}>
                                                                                    <div className="domain-name" style={{ fontWeight: 500 }}>
                                                                                        <span>{activeItem.url}</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </>
                                                        ) : (
                                                            <>
                                                                <div className='p-4'>
                                                                    <NoRecordFound imageType={`domain`} joyful={false} noRecordText={`${t('Domain not found')}`} />
                                                                </div>
                                                            </>
                                                        )}
                                                    </>
                                                ) : (
                                                    <>

                                                    </>
                                                )}
                                            </div>
                                            {inactiveWebsiteList && inactiveWebsiteList.length > 0 && (
                                                <div className="aioa_dashboard-table-pagination-main">
                                                    <>
                                                        <ShowRecordItem
                                                            offset={inactiveDomainOffset}
                                                            limit={inactiveDomainLimit}
                                                            totalRecord={inactiveDomainTotalRecord}
                                                        />
                                                        <PaginationComponent
                                                            currentPage={inactiveDomainCurrentPage}
                                                            totalRecords={inactiveDomainTotalRecord}
                                                            itemsPerPage={inactiveDomainLimit}
                                                            setCurrentPage={setInactiveDomainCurrentPage}
                                                        />
                                                    </>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>

                <div className="aioa_dashboard-custom-branding-service-domain-list-save-btn" style={{ zIndex: 9 }}>
                    <Button type="submit" variant="primary" size="lg" onClick={(e) => HandleWhiteLabelStatusUpdate(e)}>
                        <>
                            {t("Save")}
                        </>
                    </Button>
                </div>
            </div>
        </>
    )
}

export default CustomBrandingService;