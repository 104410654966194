import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { StoreProptypes } from "../../PropTypes/StoreProptypes";
import PaymentButtonBlock from "../PaymentButton";
import { useEffect, useState } from "react";
import AddNewNormalDomain from "../AddNewDomainModal/AddNormalDomain";

import "../../Assets/scss/components/add-new-domain-modal.scss";
import AddNewPrePurchasedDomainModalBlock from "../AddNewDomainModal/AddNewPrePurchasedDomain";

interface PropTypes {
    isShowHeader?: boolean,
}

const AddNewDomainButton = (prop: PropTypes) => {

    const { t } = useTranslation();

    // Redux Data
    const { user, currentWebsite, currentWebsitePlanStatus } = useSelector((store: StoreProptypes) => store);

    // Props
    const { isShowHeader } = prop;

    // State
    const [isAddNewWebsiteModal, setIsAddNewWebsiteModal] = useState<boolean>(false);
    const [isAddNewPrePurchasedWebsiteModal, setIsAddNewPrePurchasedWebsiteModal] = useState<boolean>(false);
    const [isAddMultiStoreWebsiteModal, setIsAddMultiStoreWebsiteModal] = useState<boolean>(false);
    const [disabledButton, setDisabledButton] = useState<boolean>(false);
    const [isBulkDomainModalContent, setIsBulkDomainModalContent] = useState<boolean>(false);
    const [isShowBulkDomainModalButton, setIsShowBulkDomainModalButton] = useState<boolean>(false);

    // Disabled Button Functions
    useEffect(() => {
        if (user && user.department === 1) {
            setDisabledButton(true);
        } else {
            setDisabledButton(false);
        }
    }, [user])

    // Add Normal Domain Modal
    const handleCloseAddNewWebsiteModal = () => {
        setIsAddNewWebsiteModal(false);
        setIsBulkDomainModalContent(false);
    }
    const handleShowAddNewWebsiteModal = () => setIsAddNewWebsiteModal(true);

    // Pre Purchased Domain Modal
    const handleCloseAddNewPrePurchasedWebsiteModal = () => setIsAddNewPrePurchasedWebsiteModal(false);
    const handleShowAddNewPrePurchasedWebsiteModal = () => setIsAddNewPrePurchasedWebsiteModal(true);

    // Pre Purchased Domain Modal
    const handleCloseAddNewMultiStoreWebsiteModal = () => setIsAddMultiStoreWebsiteModal(false);
    const handleShowAddNewMultiStoreWebsiteModal = () => setIsAddMultiStoreWebsiteModal(true);


    return (
        <>

            {user && (
                <>
                    {user.department === 4 ? (
                        <>
                            {
                                (user.is_multiple_domain === 1) ? (
                                    <>
                                        {user.is_multi_domain_purchased === 1 ? ( // Pre Purchased Plan
                                            <>
                                                <Button variant={`${isShowHeader ? `outline-primary` : `primary`}`} className="aioa_dashboard-icon-btn" size="lg" onClick={() => handleShowAddNewPrePurchasedWebsiteModal()} disabled={disabledButton}>
                                                    <div className="aioa_dashboard-btn-icon"><i className="material-symbols-outlined" aria-hidden="true">add</i></div>
                                                    <div className="aioa_dashboard-btn-text">{t("Add New Domain")}</div>
                                                </Button>
                                            </>
                                        ) : (
                                            <>
                                                {currentWebsite && currentWebsite.current_package_only && currentWebsite.current_package_only.length > 0 && (
                                                    <PaymentButtonBlock
                                                        activePlanDomain={currentWebsite.domain}
                                                        activePlanType={3}
                                                        buttonType={1}
                                                        activePlanInterval={'M'}
                                                        activePlanPrice={0}
                                                        buttonVariant={`primary`}
                                                        buttonBigSize={true}
                                                        buttonLabel={`Purchase Now Button`}
                                                    />
                                                )}

                                            </>
                                        )}
                                    </>
                                ) : ( // Normal domain with bulk
                                    <>
                                        <Button variant={`${isShowHeader ? `outline-primary` : `primary`}`} className="aioa_dashboard-icon-btn" size="lg" onClick={() => handleShowAddNewWebsiteModal()} disabled={disabledButton}>
                                            <div className="aioa_dashboard-btn-icon"><i className="material-symbols-outlined" aria-hidden="true">add</i></div>
                                            <div className="aioa_dashboard-btn-text">{t("Add New Domain")}</div>
                                        </Button>
                                    </>
                                )
                            }
                        </>
                    ) : (
                        <>
                            {user.is_multiple_domain === 2 ? (
                                <>
                                    {user.is_multi_domain_purchased === 1 ? ( // Silver Gold Plan
                                        <>
                                            <Button variant={`${isShowHeader ? `outline-primary` : `primary`}`} className="aioa_dashboard-icon-btn" size="lg" onClick={() => handleShowAddNewMultiStoreWebsiteModal()} disabled={disabledButton}>
                                                <div className="aioa_dashboard-btn-icon"><i className="material-symbols-outlined" aria-hidden="true">add</i></div>
                                                <div className="aioa_dashboard-btn-text">{t("Add New Domain")}</div>
                                            </Button>
                                        </>
                                    ) : (
                                        <>
                                            {currentWebsite && (
                                                <>
                                                    {currentWebsitePlanStatus.isExpired ? (
                                                        <>
                                                            {currentWebsite.expired_package_detail && currentWebsite.expired_package_detail.length > 0 && (
                                                                <>
                                                                    <PaymentButtonBlock
                                                                        buttonType={4}
                                                                        activePlanType={2}
                                                                        activePlanDomain={currentWebsite.domain}
                                                                        activePlanInterval={currentWebsite.expired_package_detail[0].subscr_interval}
                                                                        activePlanPrice={currentWebsite.expired_package_detail[0].final_price}
                                                                        websiteID={currentWebsite.id}
                                                                        activeUserID={currentWebsite.user_id}
                                                                        activePlanID={currentWebsite.expired_package_detail[0].package_id}
                                                                        buttonVariant={`danger`}
                                                                        buttonLabel={`Renew Now Button`}
                                                                    />
                                                                </>
                                                            )}
                                                        </>
                                                    ) : (currentWebsitePlanStatus.planName === null && currentWebsitePlanStatus.planExpiredData === null) ? (
                                                        <>
                                                            <PaymentButtonBlock
                                                                activePlanDomain={currentWebsite.domain}
                                                                activePlanType={2}
                                                                buttonType={1}
                                                                activePlanInterval={`M`}
                                                                activePlanPrice={0}
                                                                buttonLabel={`Select Plan`}
                                                                buttonBigSize={true}
                                                            />
                                                        </>
                                                    ) : (
                                                        <>
                                                            <PaymentButtonBlock
                                                                activePlanDomain={currentWebsite.domain}
                                                                activePlanType={2}
                                                                buttonType={1}
                                                                activePlanInterval={'M'}
                                                                activePlanPrice={0}
                                                                buttonVariant={`primary`}
                                                                buttonBigSize={true}
                                                                buttonLabel={`Purchase Now Button`}
                                                            />
                                                        </>
                                                    )}

                                                </>
                                            )}

                                        </>
                                    )}
                                </>
                            ) : (
                                <>
                                    <Button variant={`${isShowHeader ? `outline-primary` : `primary`}`} className="aioa_dashboard-icon-btn" size="lg" onClick={() => handleShowAddNewWebsiteModal()} disabled={disabledButton}>
                                        <div className="aioa_dashboard-btn-icon"><i className="material-symbols-outlined" aria-hidden="true">add</i></div>
                                        <div className="aioa_dashboard-btn-text">{t("Add New Domain")}</div>
                                    </Button>
                                </>
                            )}
                        </>
                    )}

                </>
            )}

            {/* Normal Domain Add Modal */}
            <Modal show={isAddNewWebsiteModal} onHide={handleCloseAddNewWebsiteModal} size="xl" fullscreen="lg-down" backdrop="static" centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {t("Add New Domain")}
                        {((user && user.department === 4) && (!isBulkDomainModalContent)) && (
                            <>
                                <Button variant="link" className="bulk-domain-modal-button" onClick={() => setIsBulkDomainModalContent(true)}>{t('bulk domain label')} {t('click here btn label')}</Button>
                            </>
                        )}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AddNewNormalDomain isBulkDomainModalContent={isBulkDomainModalContent} />
                </Modal.Body>
            </Modal>

            {/* Pre Purchased Domain Add Modal */}
            <Modal show={isAddNewPrePurchasedWebsiteModal} onHide={handleCloseAddNewPrePurchasedWebsiteModal} size="lg" fullscreen="md-down" backdrop="static" centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {t("Add New Domain")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AddNewPrePurchasedDomainModalBlock callBackFunction={handleCloseAddNewPrePurchasedWebsiteModal} />
                </Modal.Body>
            </Modal>

            {/* Pre Purchased Domain Add Modal */}
            <Modal show={isAddMultiStoreWebsiteModal} onHide={handleCloseAddNewMultiStoreWebsiteModal} size="lg" fullscreen="md-down" backdrop="static" centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {t("Add New Domain")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AddNewPrePurchasedDomainModalBlock callBackFunction={handleCloseAddNewMultiStoreWebsiteModal} />
                </Modal.Body>
            </Modal>

        </>
    )
}

export default AddNewDomainButton;
