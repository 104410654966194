import "../../Assets/scss/pages/order-list.scss";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import DashboardPagesTemplate from "../../Template/DashboardPagesTemplate";
import DashboardPageTitle from "../../Components/InnerPageTitle";
import { Form, InputGroup, Modal } from "react-bootstrap";
import React, { useEffect, useRef, useState } from "react";
import ShowRecordItem from "../../Components/ShowRecordItem";
import PaginationComponent from "../../Components/Pagination";
import { UserOrderItemPropTypes, bulkDomainListPropTypes } from "../../PropTypes/UserOrderDataPropTypes";
import { StoreProptypes } from "../../PropTypes/StoreProptypes";
import { getBillingHistoryService } from "../../Services/UserOrderService";
import WidgetPlanOrderCellBlock from "../../Components/OrderListTableCell/WidgetPlanOrderCell";
import AddOnPlanOrderCellBlock from "../../Components/OrderListTableCell/AddonPlanOrderCell";
import PDFPlanOrderCellBlock from "../../Components/OrderListTableCell/PDFPlanOrderCell";
import NoRecordFound from "../../Components/NoRecordFound";
import ExtensionsPlanOrderCellBlock from "../../Components/OrderListTableCell/ExtensionsPlanOrderCell";
import ScannerPlanOrderCellBlock from "../../Components/OrderListTableCell/ScannerPlanOrdercell";
import { useNavigate, useSearchParams } from "react-router-dom";
import decodeBase64 from "../../Helpers/decodeBase64";
import { SET_MESSAGE } from "../../Actions/Types";
import SearchDomainDropdownBlock from "../../Components/SearchDomainDropdown";

const OrdersPage = () => {

  // Redux Data
  const { accessToken, user} = useSelector((store: StoreProptypes) => store);

  // Function
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // Token Param
  const paymentMsg = searchParams.get("msg");
  const paymentStatus = searchParams.get("status");

  /* Update Website */
  const websiteUpdate = searchParams.get("website_update");

  /* */
  const userUpdate = searchParams.get("user_update");

  /* new website flag */
  const isNewWebsite = searchParams.get("is_new");
  const newWebsiteIDinit = searchParams.get('wtoken');
  const wid = decodeBase64(newWebsiteIDinit ? newWebsiteIDinit : '');
  const newWebsiteID = parseInt(wid);

  // Show Payment Msg
  useEffect(() => {
    if (paymentMsg && paymentStatus) {
      dispatch({
        type: SET_MESSAGE,
        payload: {
          "toast_message": t(paymentMsg),
          "toast_type": paymentStatus
        }
      })
    }
  }, [paymentMsg, paymentStatus])

  // State
  const [orderList, setOrderList] = useState<UserOrderItemPropTypes[]>();

  const [totalRecord, setTotalRecord] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(10);
  const [offset, setOffset] = useState<number>(0);
  const [terms, setTerms] = useState<string>("");
  const [domainFilter, setDomainFilter] = useState<number>(0);
  const [orderID, setOrderID] = useState<string>('');
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);

  // Pagination Functions
  useEffect(() => {
    setCurrentPage(1);
  }, [limit, terms, domainFilter]);

  useEffect(() => {
    setOffset(0);
  }, [terms, domainFilter]);

  useEffect(() => {
    setOffset(currentPage * limit - limit);
  }, [currentPage, limit]);

  // Get Order List
  const prevLimitRef = useRef<number>(10);
  const prevDomainRef = useRef<number>(0);

  useEffect(() => {
    if (accessToken && (limit === prevLimitRef.current)) {
      //console.info("Call 1");
      getBillingHistoryService(accessToken, limit, offset, domainFilter, orderID, startDate, endDate, setTotalRecord, dispatch, t, navigate, setOrderList, (isNewWebsite && isNewWebsite === "true" ? true : false), (userUpdate && userUpdate === "true" ? true : false), (websiteUpdate && websiteUpdate === "true" ? true : false), (newWebsiteID ? newWebsiteID : 0));
    }
  }, [accessToken, offset, domainFilter]);

  useEffect(() => {
    if ((limit !== prevLimitRef.current) && offset === 0) {
      if (accessToken) {
        //console.info("Call 2");
        getBillingHistoryService(accessToken, limit, offset, domainFilter, orderID, startDate, endDate, setTotalRecord, dispatch, t, navigate, setOrderList, (isNewWebsite && isNewWebsite === "true" ? true : false), (userUpdate && userUpdate === "true" ? true : false), (websiteUpdate && websiteUpdate === "true" ? true : false), (newWebsiteID ? newWebsiteID : 0));
      }
      prevLimitRef.current = limit;
    }
  }, [offset, limit]);


  // child domain List popup
  const [isChildDomainListModal, setIsChildDomainListModal] = useState<boolean>(false);
  const [childDomainList, setChildDomainList] = useState<bulkDomainListPropTypes[]>([]);
  const [invoiceID, setInvoiceID] = useState<number>(0);
  const [childDomainListType, setChildDomainListType] = useState<number>(0);

  const handleShowChildDomainListPopup = (domainList: bulkDomainListPropTypes[], invoiceID: number, type?: number) => {
    setInvoiceID(invoiceID);
    setChildDomainList(domainList);
    setIsChildDomainListModal(true);
    type && setChildDomainListType(type);
  }
  const handleCloseChildDomainListPopup = () => {
    setIsChildDomainListModal(false);
    setChildDomainList([]);
    setInvoiceID(0);
    setChildDomainListType(0);
  }


  return (
    <>
      <DashboardPagesTemplate>

        <DashboardPageTitle
          pageTitle={`${t("Orders")}`}
        />

        <div className="aioa_dashboard-order-list">
          <div className={`aioa_dashboard-order-list-filter-wrapper`} id="aioa_dashboard-order-list-filter">

            <div className="aioa_dashboard-order-list-filter-block">
              <SearchDomainDropdownBlock selectedWebsiteID={setDomainFilter} />
            </div>

            <div className="aioa_dashboard-order-list-filter-block">
              <Form.Label htmlFor='showPages'>{t("Items Per Page")}</Form.Label>
              <Form.Select
                onChange={(e) => setLimit(parseInt(e.target.value))}
                id="showPages"
                value={limit}
                aria-label={t("Items Per Page").toString()}
              >
                <option value="10">{t("10")}</option>
                <option value="20">{t("20")}</option>
                <option value="40">{t("40")}</option>
                <option value="80">{t("80")}</option>
                <option value="100">{t("100")}</option>
              </Form.Select>
            </div>

          </div>

          <div className="aioa_dashboard-table">

            <div className="aioa_dashboard-table-thead">
              <div className="aioa_dashboard-table-tr">
                <div className="aioa_dashboard-table-th tcw-100 aioa_dashboard-table-cell-sidegap" aria-hidden="true">{t("Orders #")}</div>
                <div className="aioa_dashboard-table-th tcw-auto aioa_dashboard-table-cell-sidegap" aria-hidden="true">{t("Description")}</div>
                <div className="aioa_dashboard-table-th tcw-200 text-center aioa_dashboard-table-cell-sidegap" aria-hidden="true">{t("Orders Date")}</div>
                <div className="aioa_dashboard-table-th tcw-200 text-center aioa_dashboard-table-cell-sidegap" aria-hidden="true">{t("Amount (USD)")}</div>
                <div className="aioa_dashboard-table-th tcw-200 text-center aioa_dashboard-table-cell-sidegap" aria-hidden="true">{t("Status")}</div>
                <div className="aioa_dashboard-table-th tcw-200 text-center aioa_dashboard-table-cell-sidegap" aria-hidden="true">&nbsp;</div>
              </div>
            </div>

            <div className="aioa_dashboard-table-body" aria-live='polite'>

              {orderList ? (
                <>
                  {orderList.length > 0 ? (
                    <>
                      {orderList.map((orderItem, index) => {
                        return (

                          <React.Fragment key={orderItem.id}>
                            {orderItem.product_type === 0 ? (
                              <>
                                {orderItem.scanner_plan === 1 ? (
                                  <>
                                    <ScannerPlanOrderCellBlock item={orderItem} key={orderItem.id} index={index} totalOrderItems={totalRecord} />
                                  </>
                                ) : (orderItem.platforms === "extensions") ? (
                                  <>
                                    <ExtensionsPlanOrderCellBlock item={orderItem} key={orderItem.id} index={index} totalOrderItems={totalRecord} />
                                  </>
                                ) : (
                                  <>
                                    <WidgetPlanOrderCellBlock key={orderItem.id} item={orderItem} index={index} totalOrderItems={totalRecord} handleShowChildDomainListPopup={handleShowChildDomainListPopup} />
                                  </>
                                )}

                              </>
                            ) : orderItem.product_type === 1 ? (
                              <>
                                <AddOnPlanOrderCellBlock key={orderItem.id} item={orderItem} index={index} totalOrderItems={totalRecord} />
                              </>
                            ) : (
                              <>
                                <PDFPlanOrderCellBlock key={orderItem.id} item={orderItem} index={index} totalOrderItems={totalRecord} />
                              </>
                            )}
                          </React.Fragment>
                        )
                      })}
                    </>
                  ) : (
                    <>
                      <NoRecordFound imageType={`pdf`} noRecordText={`No Invoices Found`} />
                    </>
                  )}
                </>
              ) : (
                <>

                </>
              )}

            </div>

            <div className="aioa_dashboard-table-pagination-main">
              <>
                <ShowRecordItem
                  offset={offset}
                  limit={limit}
                  totalRecord={totalRecord}
                />
                <PaginationComponent
                  currentPage={currentPage}
                  totalRecords={totalRecord}
                  itemsPerPage={limit}
                  setCurrentPage={setCurrentPage}
                />
              </>
            </div>

          </div>

        </div>

        {/* Child Domain Modal */}
        <Modal show={isChildDomainListModal} onHide={() => handleCloseChildDomainListPopup()} size="lg" fullscreen="lg-down" backdrop="static" centered>
          <Modal.Header closeButton>
            <Modal.Title>
              {user && (user.is_multiple_domain === 1 || user.is_multiple_domain === 2) ? (
                <>
                  <Modal.Title>{t(`Order`)} #{[...Array((5 - (invoiceID).toString().length))].map((i) => 0)}
                    {t(`${invoiceID} : ${childDomainList && childDomainList.length} Domain added`)}
                  </Modal.Title>
                </>
              ) : (
                <>
                  <Modal.Title>{t(`Order`)} #{[...Array((5 - (invoiceID).toString().length))].map((i) => 0)}{invoiceID} : {t(`order domain list`, { childDomain: `${childDomainList && childDomainList.length}` })}</Modal.Title>
                </>
              )}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-0">
            <div className="">

              <div className="aioa_dashboard-table">
                <div className="aioa_dashboard-table-thead">
                  <div className="aioa_dashboard-table-tr">
                    <div className="aioa_dashboard-table-th tcw-100 aioa_dashboard-table-cell-sidegap">{t("Sr No")}</div>
                    <div className="aioa_dashboard-table-th tcw-auto aioa_dashboard-table-cell-sidegap">{t("Domain")}</div>
                    <div className="aioa_dashboard-table-th tcw-150 text-center aioa_dashboard-table-cell-sidegap">{t("Select Plan")}</div>
                    <div className="aioa_dashboard-table-th tcw-150 text-center aioa_dashboard-table-cell-sidegap">{t("Price(USD)")}</div>
                  </div>
                </div>
                <div className="aioa_dashboard-table-body" aria-live='polite'>

                  {childDomainList ? (
                    <>
                      {childDomainList.length > 0 ? (
                        <>
                          {childDomainList.map((childDomainItem, index) => {
                            return (
                              <div className="aioa_dashboard-table-tr" key={1 + index}>
                                <div className="aioa_dashboard-table-td tcw-100 aioa_dashboard-table-cell-sidegap">
                                  <div className='aioa_dashboard-table-td-cell-title d-xl-none' aria-hidden="true">{t("Sr No")}</div>
                                  <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Sr No").toString()}>{1 + index}</div>
                                </div>
                                <div className="aioa_dashboard-table-td tcw-auto aioa_dashboard-table-cell-sidegap">
                                  <div className='aioa_dashboard-table-td-cell-title d-xl-none' aria-hidden="true">{t("Domain")}</div>
                                  <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Domain").toString()}>
                                    <div className="domain-name" style={{ fontWeight: 500 }}>{childDomainItem.domain}</div>
                                  </div>
                                </div>
                                <div className="aioa_dashboard-table-td tcw-150 text-center aioa_dashboard-table-cell-sidegap">
                                  <div className='aioa_dashboard-table-td-cell-title d-xl-none' aria-hidden="true">{t("Plan")}</div>
                                  <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Plan").toString()}>
                                    {childDomainListType === 2 ? (
                                      <>
                                        {childDomainItem.name}
                                      </>
                                    ) : (
                                      <>
                                        {index === 0 ? (
                                          <>
                                            {childDomainItem.name}
                                          </>
                                        ) : (
                                          <>
                                            -
                                          </>
                                        )}
                                      </>
                                    )}

                                  </div>
                                </div>
                                <div className="aioa_dashboard-table-td tcw-150 text-center aioa_dashboard-table-cell-sidegap">
                                  <div className='aioa_dashboard-table-td-cell-title d-xl-none' aria-hidden="true">{t("Price(USD)")}</div>
                                  <div className='aioa_dashboard-table-td-cell-value' aria-label={t("Price(USD)").toString()}>
                                    {childDomainListType === 2 ? (
                                      <>
                                        ${childDomainItem.final_price}/{childDomainItem.subscr_interval === "Y" ? t('Per Year') : t('Per Month')}
                                      </>
                                    ) : (
                                      <>
                                        {index === 0 ? (
                                          <>
                                            ${childDomainItem.final_price}/{childDomainItem.subscr_interval === "Y" ? t('Per Year') : t('Per Month')}
                                          </>
                                        ) : (
                                          <>
                                            -
                                          </>
                                        )}
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            )
                          })}
                        </>
                      ) : (
                        <>

                        </>
                      )}
                    </>
                  ) : (
                    <>

                    </>
                  )}
                </div>
              </div>

            </div>
          </Modal.Body>
        </Modal>


      </DashboardPagesTemplate>
    </>
  )
}

export default OrdersPage;